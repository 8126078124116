import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: relative;
  top: 85px;
  padding: 5px;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 10px);
  margin: 10px;

  span {
    font-size: 18px !important;
    padding: 10px;
    margin: 10px 0px;
  }

  input {
    border: 1.5px solid #dcdcde;
    margin: 10px 0px;
    width: calc(100% - 20px);
  }

  button {
    margin-left: 10px;
    background-color: #b9d964 !important;
    border-radius: 10px;
    height: 40px;
    width: auto;

    svg {
      background-color: #b9d964 !important;
      color: #fff !important;
    }
  }
`;
