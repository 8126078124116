import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  width: calc(100% - 20px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdcde;
  box-shadow: 0 0 1px #3e3e3e;
  border-radius: 5px;
  margin: 10px;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dcdcde;
  width: 100%;
  height: 100%;

  span {
    font-weight: 600;
    color: #3e3e3e !important;
    margin: 5px !important;
  }

  button {
    margin-left: 10px !important;
    background-color: #b9d964 !important;
    border-radius: 10px !important;
    height: 40px !important;
    width: auto !important;

    svg {
      background-color: #b9d964 !important;
      color: #fff !important;
    }
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-bottom: 1px solid #dcdcde;
  justify-content: flex-start;
  align-items: initial;
  padding: 5px;

  span {
    color: #3e3e3e !important;
    font-size: 12px !important;
    margin: 15px;
  }
`;

export const FooterContainer = styled.footer`
  display: flex;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;

  button {
    margin: 5px !important;
    padding: 0px !important;
    width: 33% !important;
    border: none !important;
    padding: 5px;

    svg {
      justify-content: flex-end !important;
      align-items: flex-end;
      height: 15px;
      width: auto;
      color: #3e3e3e !important;
      margin: 0px !important;
    }

    span {
      margin: 0px !important;
      color: #3e3e3e;
    }
  }
`;
