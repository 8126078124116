/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button, TextField, Box, Grid, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Container, ImageContainer, SubContainer, SvgContainer, useStyles } from './styles';
import api from '../../../service/api';
import { Login } from '../../../service/auth';
import ModalLogin from '../../../components/ModalLogin';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.meupedido.online/">
        Meu Pedido Online
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const SignIn: React.FC = () => {
  const [messageFailedAuthenticate, setMessageFailedAuthenticate] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      api
        .post('session/auth', values)
        .then((response: any) => {
          Login(response.data);
          history.push('/dashboard');
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          setMessageFailedAuthenticate(true);
        });
    },
  });

  return (
    <Container>
      <ImageContainer>
        <img src="https://i.imgur.com/IrihDiC.jpg" height="100%" alt="" />
      </ImageContainer>
      <>
        {messageFailedAuthenticate ? <ModalLogin onOpen onClose={() => setMessageFailedAuthenticate(false)} /> : null}
      </>
      <SubContainer>
        <div className={classes.paper}>
          <SvgContainer>
            <LockOutlinedIcon />
          </SvgContainer>
          <Typography component="h1" variant="h5">
            Entrar
          </Typography>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
              value={formik.values.email}
              onChange={formik.handleChange}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Endereço de e-mail"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              value={formik.values.password}
              onChange={formik.handleChange}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  Não tem uma conta? Inscrever-se
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </SubContainer>
    </Container>
  );
};

export default SignIn;
