import React, { useState } from 'react';
import { BuildOutlined } from '@material-ui/icons';
import { Container, SubContainer } from './styles';
import ModalAside from '../ModalAside';
import IconButton from '../IconButton';

const Aside = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  return (
    <Container>
      <SubContainer>
        <IconButton icon={<BuildOutlined />} onClick={() => setModalVisible(true)} />
        {modalVisible ? <ModalAside toDo title="TO-DO" onClose={() => setModalVisible(false)} /> : null}
      </SubContainer>
    </Container>
  );
};

export default Aside;
