import React from 'react';
import { Edit, Delete } from '@material-ui/icons';
import { Container, BodyContainer, Title, FooterContainer } from './styles';
import CardButton from '../BaseCardButton';

interface IPropsDTO {
  title: string;
  text: string;
  onClickEdit?: any;
  onClickDelete?: any;
}

const CardReminder = ({ title, text, onClickEdit, onClickDelete }: IPropsDTO) => (
  <Container>
    <BodyContainer>
      <Title>{title}</Title>
      <span>{text}</span>
    </BodyContainer>
    <FooterContainer>
      <CardButton onClick={onClickEdit} icon={<Edit />} title="Editar" />
      <CardButton onClick={onClickDelete} icon={<Delete />} title="Excluir" />
    </FooterContainer>
  </Container>
);

export default CardReminder;
