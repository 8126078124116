/* eslint-disable max-len */
import React, { useState } from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Container } from './styles';
import cash from '../../util/cash';
import IconButton from '../IconButton';

interface IItems {
  items: any[];
}

interface IPropsDTO {
  itemName: string;
  quantity: number;
  price: number;
  itemTotalValue: number;
  subItem: IItems;
}

const TableOrderDetails = ({ subItem, itemName, quantity, price, itemTotalValue }: IPropsDTO) => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <TableBody>
      <TableRow key={itemName}>
        <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '12px' }}>
          {itemName}
        </TableCell>
        <TableCell align="right">{quantity}</TableCell>
        <TableCell align="right">{cash(price)}</TableCell>
        <TableCell align="right" style={{ padding: '0px' }}>
          {cash(itemTotalValue)}
        </TableCell>
      </TableRow>
      {subItem
        ? /* @ts-ignore */
          subItem.map((subItemMap) => (
            <TableRow>
              <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {subItemMap.name}
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          ))
        : null}
      {subItem
        ? /* @ts-ignore */
          subItem[0]?.items.map((orderComplementItem) => (
            <TableRow>
              <TableCell scope="row">{orderComplementItem.name}</TableCell>
              <TableCell align="right">{orderComplementItem.quantity}</TableCell>
              <TableCell align="right">{cash(orderComplementItem.price)}</TableCell>
            </TableRow>
          ))
        : null}
    </TableBody>
  );
};

export default TableOrderDetails;
