import React from 'react';
import { Container, Main } from './styles';
import MapDrawer from '../../../components/MapDrawer';
import NavBar from '../../../components/NavBar';

const DeliveryArea = () => (
  <Container>
    <Main>
      <MapDrawer />
      <NavBar />
    </Main>
  </Container>
);

export default DeliveryArea;
