import React from 'react';
import { Container, Main } from './styles';
import NavBar from '../../../components/NavBar';
import ProfileBody from '../../../components/Profile';

const Profile = () => (
  <Container>
    <Main>
      <ProfileBody />
      <NavBar />
    </Main>
  </Container>
);

export default Profile;
