import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #b9d964;
  border-radius: 20px;
  border: none;

  span {
    font-weight: 600;
    color: #fff;
    text-align: center;
    font-size: 18px;
  }
  &:hover {
    background-color: #a6ce39;
  }
`;
