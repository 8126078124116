/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { Container, SubContainer } from './styles';
import IconButton from '../IconButton';

interface IPropsDTO {
  onClick?: any;
  icon?: any;
  title: string;
  expand?: boolean;
  name?: string;
  iconExpand?: any;
}

const CardButton = ({ onClick, name, icon, title, expand = false, iconExpand }: IPropsDTO) => {
  const [expandVisible] = useState<boolean>(expand);
  return (
    <Container name={name} onClick={onClick}>
      <IconButton icon={icon} />
      <span>{title}</span>
      {expandVisible ? (
        <SubContainer>
          <IconButton icon={iconExpand} />
        </SubContainer>
      ) : null}
    </Container>
  );
};

export default CardButton;
