import * as React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { DeleteOutline, Edit } from '@material-ui/icons/';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';

interface IRoutesDTO {
  handleEditComplement: any;
}
const TableComplement = ({ handleEditComplement }: IRoutesDTO) => {
  function createData(id: string, name: string, increments: any) {
    return {
      id,
      name,
      increments,
    };
  }
  function Row(props: { row: ReturnType<typeof createData> }) {
    const dispatch = useDispatch();
    const complementData: any = useSelector((state: any) => state.complementData);
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const handleDeleteComplement = (complementChange: any) => {
      const index = complementData.findIndex((id: any) => id.id === complementChange.id);
      complementData.splice(index, 1);
      const complement = [...complementData];
      dispatch({ type: 'REMOVE_COMPLEMENT', complement });
      if (!complementChange.isNew) {
        dispatch({ type: 'DELETE_COMPLEMENT', complementChange });
      }
    };

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Edit id={row.id} onClick={() => handleEditComplement(row)} />
          </TableCell>
          <TableCell>
            <DeleteOutline id={row.id} onClick={() => handleDeleteComplement(row)} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 10 }}>
                <Typography variant="inherit" gutterBottom component="div" />
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Preço</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.increments
                      ? row.increments.map((increments: any) => (
                          <TableRow key={increments.name}>
                            <TableCell component="th" scope="row">
                              {increments.name}
                            </TableCell>
                            <TableCell>{increments.price}</TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const complementData: any = useSelector((state: any) => state.complementData);
  return (
    <Container>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell align="left">Grupos de complementos que compõem esse item</TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {complementData.map((row: any) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TableComplement;
