/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-one-expression-per-line */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Lens } from '@material-ui/icons';
import moment from 'moment';
import { Table, TableHead, TableCell, TableRow, Paper } from '@material-ui/core';
import api from '../../service/api';
import { IOrderDTO } from '../../interfaces/IOrderDTO';
import cash from '../../util/cash';
import {
  Container,
  ApprovedContainer,
  ConcludedContainer,
  ReceivedContainer,
  TrafficContainer,
  CanceledContainer,
  HeaderContainer,
  TableContainer,
  ContactContainer,
  FooterContainer,
  FooterTable,
  TextContainer,
  Text,
  TextSpecial,
  Title,
} from './styles';
import TableOrderDetails from '../TableOrderDetails';

interface IPropsDTO {
  orderId: string;
}

const OrderManegerComponent = ({ orderId }: IPropsDTO) => {
  const [orders, setOrders] = useState<IOrderDTO>();
  const [token] = useState(localStorage.getItem('token'));
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    api.get(`/orders/${orderId}`, config).then((response) => {
      setOrders(response.data);
    });
  }, [orderId]);

  function handleDeliveryType(type: string) {
    switch (type) {
      case 'E':
        return 'Entrega';
      case 'R':
        return 'Retirada';
      case 'L':
        return 'Local';
      default:
        return '';
    }
  }

  return (
    <Container>
      {/* @ts-ignore */}
      {orders?.status === 'TRAFFIC' ? (
        <TrafficContainer>
          <HeaderContainer>
            <TextContainer>
              <Title>Pedido:</Title>
              <Text>#{orders ? orders.shortReference : '#'}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Hora do Pedido:</Title>
              <Text>{moment(orders.createdDate).format('h:mm:ss a')}</Text>
            </TextContainer>
            <Lens />
          </HeaderContainer>
          <TableContainer>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Preço (Un)</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              {orders
                ? orders.items.map((orderItem) => (
                    <TableOrderDetails
                      subId={orderItem.SubItems?.map((item) => item.items?.map((sub) => sub.id))}
                      key={orderItem.id}
                      itemName={orderItem.name}
                      price={orderItem.price}
                      quantity={orderItem.quantity}
                      itemTotalValue={orderItem.itemTotalValue}
                      subItem={orderItem.SubItems}
                    />
                  ))
                : null}
            </Table>
            <FooterTable>
            <Title style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</Title>
              <Text>
                R$
                {orders ? orders.total : 0}
              </Text>
            </FooterTable>
          </TableContainer>
          <FooterContainer>
            <ContactContainer>
              <TextContainer>
                <Title>Contato:</Title>
              </TextContainer>
              <TextContainer>
                <Text>Nome:</Text>
                <Text>{orders.customer.name}</Text>
              </TextContainer>
              <TextContainer>
                <Text>Celular:</Text>
                <Text>{orders.customer.phone}</Text>
              </TextContainer>
            </ContactContainer>
            {orders.payment.paymentForm === 'DINHEIRO' ? (
              <ContactContainer>
                <TextContainer>
                  <Title>Informações do pagamento:</Title>
                </TextContainer>
                <TextContainer>
                  <Text>Valor total:</Text>
                  <Text>{cash(orders.payment.total)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Valor pago:</Text>
                  <Text>{cash(orders.payment.cashValue)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Troco:</Text>
                  <Text>{cash(orders.payment.changeValue)}</Text>
                </TextContainer>
              </ContactContainer>
            ) : null}
          </FooterContainer>
          <FooterContainer>
            <TextContainer>
              <Title>Tipo de Entrega:</Title>
              <Text>{handleDeliveryType(orders.deliveryType)}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Tipo de Pagamento:</Title>
              <TextSpecial> {` ${orders.payment.paymentForm}`}</TextSpecial>
            </TextContainer>
          </FooterContainer>
        </TrafficContainer>
      ) : null}
      {orders?.status === 'RECEIVED' ? (
        <ReceivedContainer>
          <HeaderContainer>
            <TextContainer>
              <Title>Pedido:</Title>
              <Text>#{orders ? orders.shortReference : '#'}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Hora do Pedido:</Title>
              <Text>{moment(orders.createDate).format('h:mm:ss a')}</Text>
            </TextContainer>
            <Lens />
          </HeaderContainer>
          <TableContainer>
          <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Preço (Un)</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              {orders
                ? orders.items.map((orderItem) => (
                    <TableOrderDetails
                      subId={orderItem.SubItems?.map((item) => item.items?.map((sub) => sub.id))}
                      key={orderItem.id}
                      itemName={orderItem.name}
                      price={orderItem.price}
                      quantity={orderItem.quantity}
                      itemTotalValue={orderItem.itemTotalValue}
                      subItem={orderItem.SubItems}
                    />
                  ))
                : null}
          </Table>
            <FooterTable>
              <Title style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</Title>
              <Text>
                R$
                {orders ? orders.total : 0}
              </Text>
            </FooterTable>
          </TableContainer>
          <FooterContainer>
            <ContactContainer>
              <TextContainer>
                <Title>Contato:</Title>
              </TextContainer>
              <TextContainer>
                <Text>Nome:</Text>
                <Text>{orders.customer.name}</Text>
              </TextContainer>
              <TextContainer>
                <Text>Celular:</Text>
                <Text>{orders.customer.phone}</Text>
              </TextContainer>
            </ContactContainer>
            {orders.payment.paymentForm === 'DINHEIRO' ? (
              <ContactContainer>
                <TextContainer>
                  <Title>Informações do pagamento:</Title>
                </TextContainer>
                <TextContainer>
                  <Text>Valor total:</Text>
                  <Text>{cash(orders.payment.total)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Valor pago:</Text>
                  <Text>{cash(orders.payment.cashValue)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Troco:</Text>
                  <Text>{cash(orders.payment.changeValue)}</Text>
                </TextContainer>
              </ContactContainer>
            ) : null}
          </FooterContainer>
          <FooterContainer>
            <TextContainer>
              <Title>Tipo de Entrega:</Title>
              <Text>{handleDeliveryType(orders.deliveryType)}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Tipo de Pagamento:</Title>
              <TextSpecial> {` ${orders.payment.paymentForm}`}</TextSpecial>
            </TextContainer>
          </FooterContainer>
        </ReceivedContainer>
      ) : null}
      {orders?.status === 'CONCLUDED' ? (
        <ConcludedContainer>
          <HeaderContainer>
            <TextContainer>
              <Title>Pedido:</Title>
              <Text>#{orders ? orders.shortReference : '#'}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Hora do Pedido:</Title>
              <Text>{moment(orders.createDate).format('h:mm:ss a')}</Text>
            </TextContainer>
            <Lens />
          </HeaderContainer>
          <TableContainer>
          <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Preço (Un)</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              {orders
                ? orders.items.map((orderItem) => (
                    <TableOrderDetails
                      subId={orderItem.SubItems?.map((item) => item.items?.map((sub) => sub.id))}
                      key={orderItem.id}
                      itemName={orderItem.name}
                      price={orderItem.price}
                      quantity={orderItem.quantity}
                      itemTotalValue={orderItem.itemTotalValue}
                      subItem={orderItem.SubItems}
                    />
                  ))
                : null}
          </Table>
            <FooterTable>
              <Title style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</Title>
              <Text>
                R$
                {orders ? orders.total : 0}
              </Text>
            </FooterTable>
          </TableContainer>
          <FooterContainer>
            <ContactContainer>
              <TextContainer>
                <Title>Contato:</Title>
              </TextContainer>
              <TextContainer>
                <Text>Nome:</Text>
                <Text>{orders.customer.name}</Text>
              </TextContainer>
              <TextContainer>
                <Text>Celular:</Text>
                <Text>{orders.customer.phone}</Text>
              </TextContainer>
            </ContactContainer>
            {orders.payment.paymentForm === 'DINHEIRO' ? (
              <ContactContainer>
                <TextContainer>
                  <Title>Informações do pagamento:</Title>
                </TextContainer>
                <TextContainer>
                  <Text>Valor total:</Text>
                  <Text>{cash(orders.payment.total)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Valor pago:</Text>
                  <Text>{cash(orders.payment.cashValue)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Troco:</Text>
                  <Text>{cash(orders.payment.changeValue)}</Text>
                </TextContainer>
              </ContactContainer>
            ) : null}
          </FooterContainer>
          <FooterContainer>
            <TextContainer>
              <Title>Tipo de Entrega:</Title>
              <Text>{handleDeliveryType(orders.deliveryType)}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Tipo de Pagamento:</Title>
              <TextSpecial> {` ${orders.payment.paymentForm}`}</TextSpecial>
            </TextContainer>
          </FooterContainer>
        </ConcludedContainer>
      ) : null}
      {orders?.status === 'APPROVED' ? (
        <ApprovedContainer>
          <HeaderContainer>
            <TextContainer>
              <Title>Pedido:</Title>
              <Text>#{orders ? orders.shortReference : '#'}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Hora do Pedido:</Title>
              <Text>{moment(orders.createdDate).format('h:mm:ss a')}</Text>
            </TextContainer>
            <Lens />
          </HeaderContainer>
          <TableContainer>
          <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Preço (Un)</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              {orders
                ? orders.items.map((orderItem) => (
                    <TableOrderDetails
                      subId={orderItem.SubItems?.map((item) => item.items?.map((sub) => sub.id))}
                      key={orderItem.id}
                      itemName={orderItem.name}
                      price={orderItem.price}
                      quantity={orderItem.quantity}
                      itemTotalValue={orderItem.itemTotalValue}
                      subItem={orderItem.SubItems}
                    />
                  ))
                : null}
          </Table>
            <FooterTable>
              <Title style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</Title>
              <Text>
                R$
                {orders ? orders.total : 0}
              </Text>
            </FooterTable>
          </TableContainer>
          <FooterContainer>
            <ContactContainer>
              <TextContainer>
                <Title>Contato:</Title>
              </TextContainer>
              <TextContainer>
                <Text>Nome:</Text>
                <Text>{orders.customer.name}</Text>
              </TextContainer>
              <TextContainer>
                <Text>Celular:</Text>
                <Text>{orders.customer.phone}</Text>
              </TextContainer>
            </ContactContainer>
            {orders.payment.paymentForm === 'DINHEIRO' ? (
              <ContactContainer>
                <TextContainer>
                  <Title>Informações do pagamento:</Title>
                </TextContainer>
                <TextContainer>
                  <Text>Valor total:</Text>
                  <Text>{cash(orders.payment.total)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Valor pago:</Text>
                  <Text>{cash(orders.payment.cashValue)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Troco:</Text>
                  <Text>{cash(orders.payment.changeValue)}</Text>
                </TextContainer>
              </ContactContainer>
            ) : null}
          </FooterContainer>
          <FooterContainer>
            <TextContainer>
              <Title>Tipo de Entrega:</Title>
              {/* @ts-ignore */}
              <Text>{handleDeliveryType(orders.deliveryType)}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Tipo de Pagamento:</Title>
              {/* @ts-ignore */}
              <TextSpecial> {` ${orders.payment.paymentForm}`}</TextSpecial>
            </TextContainer>
          </FooterContainer>
        </ApprovedContainer>
      ) : null}
      {orders?.status === 'CANCELED' ? (
        <CanceledContainer>
          <HeaderContainer>
            <TextContainer>
              <Title>Pedido:</Title>
              <Text>#{orders ? orders.shortReference : '#'}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Hora do Pedido:</Title>
              <Text>{moment(orders.createDate).format('h:mm:ss a')}</Text>
            </TextContainer>
            <Lens />
          </HeaderContainer>
          <TableContainer>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Preço (Un)</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              {orders
                ? orders.items.map((orderItem) => (
                    <TableOrderDetails
                      subId={orderItem.SubItems?.map((item) => item.items?.map((sub) => sub.id))}
                      key={orderItem.id}
                      itemName={orderItem.name}
                      price={orderItem.price}
                      quantity={orderItem.quantity}
                      itemTotalValue={orderItem.itemTotalValue}
                      subItem={orderItem.SubItems}
                    />
                  ))
                : null}
            </Table>
            <FooterTable>
            <Title style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</Title>
              <Text>
                R$
                {orders ? orders.total : 0}
              </Text>
            </FooterTable>
          </TableContainer>
          <FooterContainer>
            <ContactContainer>
              <TextContainer>
                <Title>Contato:</Title>
              </TextContainer>
              <TextContainer>
                <Text>Nome:</Text>
                <Text>{orders.customer.name}</Text>
              </TextContainer>
              <TextContainer>
                <Text>Celular:</Text>
                <Text>{orders.customer.phone}</Text>
              </TextContainer>
            </ContactContainer>
            {orders.payment.paymentForm === 'DINHEIRO' ? (
              <ContactContainer>
                <TextContainer>
                  <Title>Informações do pagamento:</Title>
                </TextContainer>
                <TextContainer>
                  <Text>Valor total:</Text>
                  <Text>{cash(orders.payment.total)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Valor pago:</Text>
                  <Text>{cash(orders.payment.cashValue)}</Text>
                </TextContainer>
                <TextContainer>
                  <Text>Troco:</Text>
                  <Text>{cash(orders.payment.changeValue)}</Text>
                </TextContainer>
              </ContactContainer>
            ) : null}
          </FooterContainer>
          <FooterContainer>
            <TextContainer>
              <Title>Tipo de Entrega:</Title>
              <Text>{handleDeliveryType(orders.deliveryType)}</Text>
            </TextContainer>
            <TextContainer>
              <Title>Tipo de Pagamento:</Title>
              <TextSpecial> {` ${orders.payment.paymentForm}`}</TextSpecial>
            </TextContainer>
          </FooterContainer>
        </CanceledContainer>
      ) : null}
    </Container>
  );
};

export default OrderManegerComponent;
