import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Input from '../InputBase';
import Button from '../Button';
import api from '../../service/api';
import { IStoreDTO, IStoreParamsDTO } from '../../interfaces/IStoreDTO';
import { ICategoriesDTO } from '../../interfaces/IProductDTO';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {
  ButtonSaveContainer,
  ButtonsContainer,
  Card,
  CardsContainer,
  Container,
  ImageContainer,
  InputContainer,
  Select,
  SelectContainer,
  SubContainer,
  SubContainer1,
  SubContainer2,
  Text,
  Title,
  TitleCard,
  TitleContainer,
} from './styles';
import Dropzone from '../Dropzone';

const typecast = ['G', 'P', 'C', 'I'];

const ordenation = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];

function handleTypecast(type: string) {
  switch (type) {
    case 'G':
      return 'Grupo';
    case 'P':
      return 'Produto';
    case 'C':
      return 'Adicional';
    case 'I':
      return 'Incremento';
    default:
      return '';
  }
}

const ProductForm = () => {
  const history = useHistory();
  const [groupCategory, setGroupCategory] = useState<ICategoriesDTO[]>([]);
  const [productCategory, setProductCategory] = useState<ICategoriesDTO[]>([]);
  const [incrementCategory, setIncrementCategory] = useState<ICategoriesDTO[]>([]);
  const [groupSelected, setGroupSelected] = useState<ICategoriesDTO>();
  const [productSelected, setProductSelected] = useState<ICategoriesDTO>();
  const [incrementSelected, setIncrementSelected] = useState<ICategoriesDTO>();
  const [name, setName] = useState<string>();
  const [maxQuantity, setMaxQuantity] = useState<number>();
  const [minQuantity, setMinQuantity] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [description, setDescription] = useState<string>();
  const [category, setCategory] = useState<string>();
  const [storeArray] = useState(localStorage.getItem('authStore'));
  const [stores, setStores] = useState<IStoreParamsDTO>();
  const [store, setStore] = useState<IStoreDTO>();
  const [ordenationProduct, setOrdenationProduction] = useState<number>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [snakOpen, setSnackOpen] = useState<boolean>();
  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

  const [token] = useState(localStorage.getItem('token'));
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const thumbs = selectedFile ? (
    <>
      <div key={selectedFile.name}>
        <img src={URL.createObjectURL(selectedFile)} />
      </div>
    </>
  ) : null;

  // function handleStore() {
  //   if (storeArray !== null) setStores(JSON.parse(storeArray));
  // }

  // useEffect(() => {
  //   handleStore();
  // }, [handleStore]);

  useEffect(() => {
    api.get(`stores/${stores?.store_name}`, config).then((response) => {
      setStore(response.data);
    });
  }, [stores?.store_name]);

  useEffect(() => {
    api.get('products/group', config).then((response) => {
      setGroupCategory(response.data);
    });
  }, [groupSelected]);

  useEffect(() => {
    if (groupSelected !== undefined) {
      api.get(`products/product/${groupSelected}`, config).then((response) => {
        setProductCategory(response.data);
      });
    }
  }, [groupSelected]);

  useEffect(() => {
    if (productSelected !== undefined) {
      api.get(`products/increment/${productSelected}`, config).then((response) => {
        setIncrementCategory(response.data);
      });
    }
  }, [productSelected]);

  function handleInput(set: any, value: any) {
    set(value.target.value);
  }

  async function handleSubmit(type: string) {
    const productDataForm = new FormData();
    console.log();
    if (category === 'G') {
      productDataForm.append('typecast', `${category}`);
      productDataForm.append('name', `${name ? name : ''}`);
      productDataForm.append('description', `${description ? description : ''}`);
      groupSelected?.id ? productDataForm.append('productId', groupSelected.id) : null;
      productDataForm.append('maximum', `${maxQuantity ? maxQuantity : ''}`);
      productDataForm.append('minimum', `${minQuantity ? minQuantity : ''}`);
      productDataForm.append('calculation', 'N');
      productDataForm.append('status', 'A');
      productDataForm.append('storeId', `${store ? store.id : ''}`);
      productDataForm.append('primaryLevel', 'Y');
      productDataForm.append('complementPagination', '1');
      productDataForm.append('ordernation', `${ordenationProduct}`);
      selectedFile ? productDataForm.append('image', selectedFile) : null;
      productDataForm.append('prodMode', '0');

      return await api.post('products/', productDataForm, config).then((res) => {
        if (res.data.success === true) {
          setSnackOpen(true);
          setTimeout(() => history.goBack(), 3000);
        }
      });
    }
    if (category === 'P') {
      console.log(groupSelected);
      productDataForm.append('typecast', `${category}`);
      productDataForm.append('name', `${name ? name : ''}`);
      productDataForm.append('productId', `${groupSelected}`);
      productDataForm.append('price', `${price}`);
      productDataForm.append('description', `${description ? description : ''}`);
      productDataForm.append('maximum', `${maxQuantity ? maxQuantity : ''}`);
      productDataForm.append('minimum', `${minQuantity ? minQuantity : ''}`);
      productDataForm.append('calculation', 'N');
      productDataForm.append('status', 'A');
      productDataForm.append('storeId', `${store ? store.id : ''}`);
      productDataForm.append('primaryLevel', 'N');
      productDataForm.append('complementPagination', '1');
      productDataForm.append('ordernation', `${ordenationProduct}`);
      selectedFile ? productDataForm.append('image', selectedFile) : null;
      productDataForm.append('prodMode', '0');

      return await api.post('products/', productDataForm, config).then((res) => {
        if (res.data.success === true) {
          setSnackOpen(true);
        }
      });
    }
  }
  return (
    <Container>
      <SubContainer>
        <SubContainer1>
          <TitleContainer>
            <Title>Adicionar Produto</Title>
            <Dropzone onFileUploaded={setSelectedFile} />
          </TitleContainer>
          <CardsContainer>
            <Card>
              <TitleContainer>
                <TitleCard>Dados Cadastrais:</TitleCard>
              </TitleContainer>
              <InputContainer>
                <SelectContainer>
                  <Text>Classificação:</Text>
                  <Select onChange={(value) => handleInput(setCategory, value)}>
                    <option value="">{}</option>
                    {typecast?.map((typecastP) => (
                      <option value={typecastP}>{handleTypecast(typecastP)}</option>
                    ))}
                  </Select>
                </SelectContainer>
                <InputContainer>
                  <Text>Nome do Produto:</Text>
                  <Input type="text" placeholder="" onChange={(value: string) => handleInput(setName, value)} />
                </InputContainer>
              </InputContainer>
              <InputContainer>
                {category === 'P' ? (
                  <SelectContainer>
                    <Text>Grupo:</Text>
                    <Select onChange={(value) => handleInput(setGroupSelected, value)}>
                      <option value="">{}</option>
                      {groupCategory?.map((groupP) => (
                        <option value={groupP.id}>{groupP.name}</option>
                      ))}
                    </Select>
                  </SelectContainer>
                ) : null}
                {category === 'C' ? (
                  <>
                    <SelectContainer>
                      <Text>Grupo:</Text>
                      <Select onChange={(value) => handleInput(setGroupSelected, value)}>
                        <option value="">{}</option>
                        {groupCategory?.map((groupP) => (
                          <option value={groupP.id}>{groupP.name}</option>
                        ))}
                      </Select>
                    </SelectContainer>
                    <SelectContainer>
                      <Text>Produto:</Text>
                      {productCategory?.length && (
                        <Select onChange={(value) => handleInput(setProductSelected, value)}>
                          <option value="">{}</option>
                          {productCategory?.map((productP) => (
                            <option value={productP.id}>{productP.name}</option>
                          ))}
                        </Select>
                      )}
                    </SelectContainer>
                  </>
                ) : null}
                {category === 'I' ? (
                  <>
                    <SelectContainer>
                      <Text>Grupo:</Text>
                      <Select onChange={(value) => handleInput(setGroupSelected, value)}>
                        <option value="">{}</option>
                        {groupCategory?.map((groupP) => (
                          <option value={groupP.id}>{groupP.name}</option>
                        ))}
                      </Select>
                    </SelectContainer>
                    <SelectContainer>
                      <Text>Produto:</Text>
                      {productCategory?.length && (
                        <Select onChange={(value) => handleInput(setProductSelected, value)}>
                          <option value="">{}</option>
                          {productCategory?.map((productP) => (
                            <option value={productP.id}>{productP.name}</option>
                          ))}
                        </Select>
                      )}
                    </SelectContainer>
                    <SelectContainer>
                      <Text>Adicional:</Text>
                      {incrementCategory?.length && (
                        <Select onChange={(value) => handleInput(setIncrementSelected, value)}>
                          {incrementCategory?.map((incrementP) => (
                            <option value={incrementP.id}>{incrementP.name}</option>
                          ))}
                        </Select>
                      )}
                    </SelectContainer>
                  </>
                ) : null}
              </InputContainer>
              <InputContainer>
                <Text>Descrição:</Text>
                <textarea onChange={(value) => handleInput(setDescription, value)} />
              </InputContainer>
            </Card>
          </CardsContainer>
        </SubContainer1>
        <SubContainer2>
          <TitleContainer>
            <Title>Produto</Title>
            <ImageContainer>{thumbs}</ImageContainer>
          </TitleContainer>
          <CardsContainer>
            <Card>
              <TitleContainer>
                <TitleCard>Quantidade do Produto:</TitleCard>
              </TitleContainer>
              <InputContainer>
                <InputContainer>
                  <Text>Mínima:</Text>
                  <Input
                    type="text"
                    placeholder="Quantidade mínima do produto"
                    onChange={(value: string) => handleInput(setMinQuantity, value)}
                  />
                </InputContainer>
                <InputContainer>
                  <Text>Máxima:</Text>
                  <Input
                    type="text"
                    placeholder="Quantidade máxima do produto"
                    onChange={(value: string) => handleInput(setMaxQuantity, value)}
                  />
                </InputContainer>
              </InputContainer>
              <InputContainer>
                <InputContainer>
                  <Text>Preço:</Text>
                  <Input type="text" placeholder="" onChange={(value: string) => handleInput(setPrice, value)} />
                </InputContainer>
                <InputContainer>
                  <Text>Moeda:</Text>
                  <Input disabled type="text" placeholder="R$" onChange={handleInput} />
                </InputContainer>
              </InputContainer>
              <InputContainer>
                <SelectContainer>
                  <Text>Ordem de Apresentação:</Text>
                  <Select onChange={(value) => handleInput(setOrdenationProduction, value)}>
                    {ordenation?.map((ordenationP) => (
                      <option value={ordenationP}>{ordenationP}</option>
                    ))}
                  </Select>
                </SelectContainer>
              </InputContainer>
            </Card>
          </CardsContainer>
          <ButtonsContainer>
            <ButtonSaveContainer>
              <Button
                onClick={() => {
                  handleSubmit(category ? category : 'G');
                }}
                title="SALVAR"
              />
            </ButtonSaveContainer>
          </ButtonsContainer>
        </SubContainer2>
      </SubContainer>
      {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            Produto Adicionado com Sucesso !
          </Alert>
        </Snackbar>
      ) : null}
    </Container>
  );
};

export default ProductForm;
