import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 200px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
`;

export const SubContainer = styled.div`
min-width: 100%;
  tr {
    display: flex;
    flex:1;
    justify-content: space-around !important;
    align-items: center;
    border-bottom: 1px solid #dcdcde;
    margin: 5px;
  }
    th {
      padding: 5px;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: none !important;
      color: #3e3e3e;
      font-weight: 550;
      font-size: 12px;
    }

    td {
      padding: 5px;
      font-size: 12px;
      border: none !important;
      color: #636363;
      margin-right: 20px;
    }
  }
`;
