import React from 'react';
import { Container, Main } from './styles';
import Aside from '../../../components/Aside';
import NavBar from '../../../components/NavBar';
import Customer from '../../../components/CustomerList';

const CustomerList = () => (
  <Container>
    <Main>
      <NavBar />
      <Customer />
    </Main>
    <Aside />
  </Container>
);

export default CustomerList;
