import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: '#fefefe !important';
  height: auto;
  padding-left: 16.875rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  width: calc(100% - 50px);
  background-color: '#fefefe';
  height: auto;
  padding: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  margin-bottom: 20px;
  height: auto;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`;

export const Title = styled.span`
  color: #636363;
  font-weight: 580;
  font-size: 24px;
  text-align: left;
  font-size: 20px !important;
  align-items: center;
  color: #636363;
`;

export const TitleChart = styled.span`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 0 15px 15px 15px;
  display: flex;
  color: #636363;
  margin-left: 15px;
  font-weight: 580;
  font-size: 20px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const CardSubContainer = styled.div`
  display: flex;

  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 5px 10px 5px;
`;

export const CardsContent = styled.div`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fefefe;
  width: calc(100% - 20px);
  min-height: 270px;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
`;

export const InnerCards = styled.div`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #ffffff;
  height: auto;
  flex-direction: column;
`;

export const OrdersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const OrdersContent = styled.div`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #ffff;
  width: calc(100% - 20px);
  min-height: 270px;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const GraphContent = styled.div`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #ffff;
  width: calc(100% - 20px);
  min-height: 270px;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
`;
export const Line = styled.div`
  margin-bottom: 5px;
  background-color: #3e3e3e;
  width: 100%;
  height: 0.9px;
`;
