/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { TextField, Grid, Snackbar } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { colors } from '../../style/styles';
import api from '../../service/api';
import { IStoreDTO, IStoreParamsDTO } from '../../interfaces/IStoreDTO';
import Input from '../InputBase';
import Button from '../Button';
import { cepMask } from '../../util/inputMask';
import {
  Container,
  SubContainer,
  SubContainer2,
  TitleContainer,
  Title,
  ImageContainer,
  CardsContainer,
  Card,
  TextContainer,
  TitleCard,
  Text,
  InputContainer,
  SelectContainer,
  Select,
  ButtonsContainer,
  ButtonSaveContainer,
  ContainerDropZone,
} from './styles';
import phone from '../../util/phone';

const Profile = () => {
  const [storeArray] = useState(localStorage.getItem('authStore'));
  const [stores, setStores] = useState<IStoreParamsDTO>();
  const [store, setStore] = useState<IStoreDTO>();
  const [cep, setCep] = useState('');
  const [cepUnity, setCepUnity] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [city, setCity] = useState('');
  const [bairro, setBairro] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState();
  const [numberUnity, setNumberUnity] = useState('');
  const [maxTime, setMaxTime] = useState();
  const [minTime, setMinTime] = useState();
  const [categoryStore, setCategoryStore] = useState();
  const [shouldEnable, setShouldEnable] = useState<boolean>(false);
  const [category, setCategory] = useState<IStoreParamsDTO>();
  const time = ['', '10', '20', '30', '40', '50', '60'];
  const [files, setFiles] = useState<File[]>([]);
  const [loader, setLoader] = useState<boolean>();
  const [snakOpen, setSnackOpen] = useState<boolean>();
  const [snakOpenError, setSnackOpenError] = useState<boolean>();
  const [snakOpenSave, setSnackOpenSave] = useState<boolean>();
  const [changeImg, setChangeImg] = useState<boolean>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setChangeImg(true);
      setSelectedFile(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });
  const [token] = useState(localStorage.getItem('token'));
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const thumbs = files.map((file) => (
    <div key={file.name}>
      <img src={URL.createObjectURL(file)} />
    </div>
  ));

  function handleStore() {
    if (storeArray !== null) setStores(JSON.parse(storeArray));
  }

  const handleUnity = () => {
    if (cep !== cepUnity) {
      setSnackOpenSave(true);
      return;
    }

    const productDataForm = new FormData();
    productDataForm.append('postalCode', cep);
    productDataForm.append('street', street);
    productDataForm.append('number', numberUnity);
    productDataForm.append('neighborhood', bairro);
    productDataForm.append('city', city);
    productDataForm.append('uf', selectedState);

    if (changeImg) {
      // @ts-ignore
      productDataForm.append('image', selectedFile);
    }

    api.put('/address/unity', productDataForm, config).then((res) => {
      if (res.data.sucess === true) {
        handleSubmit();
        // setSnackOpen(true);
      }
    });
  };

  function handleStatus(status: string) {
    switch (status) {
      case 'A':
        return 'Ativo';
      case 'I':
        return 'Inativo';
      default:
        return '';
    }
  }

  useEffect(() => {
    handleStore();
  }, []);

  function handleInput(set: any, value: any) {
    set(value.target.value);
  }

  function handleCepFormated(cepValue: any) {
    cepMask(cepValue);
    setCep(cepValue.target.value);
    return cepValue;
  }

  async function handleSubmit() {
    const putStore = {
      whatsapp: number,
      minDeliveryTime: minTime,
      maxDeliveryTime: maxTime,
      category: categoryStore,
    };
    await api.put(`stores/${store?.id}`, putStore, config).then((res) => {
      if (res.status === 200) {
        setSnackOpen(true);
      }
    });
  }

  const handleCep = async () => {
    if (cep) {
      const cepSplit = cep.split('-');

      const url = `https://viacep.com.br/ws/${cepSplit[0]}${cepSplit[1]}/json/`;
      await axios.get(url).then((res) => {
        const { data } = res;
        if (data.erro === true) {
          setSnackOpenError(true);
          return;
        }
        setCepUnity(cep);
        setSelectedState(data.uf);
        setCity(data.localidade);
        setBairro(data.bairro);
        setStreet(data.logradouro);
      });
    } else {
      setSnackOpenError(true);
    }
  };

  useEffect(() => {
    api.get('stores/categories/list', config).then((response) => {
      setCategory(response.data);
    });
  }, []);

  useEffect(() => {
    api.get(`stores/${stores?.store_name}`).then((response) => {
      setChangeImg(false);
      setStore(response.data);
      setMaxTime(response.data.maxDeliveryTime);
      setMinTime(response.data.minDeliveryTime);
      setCategoryStore(response.data.category);
      setNumber(response.data.whatsapp);
    });
  }, [stores?.store_name]);

  useEffect(() => {
    setLoader(true);
    api.get('/address/unity', config).then((response) => {
      const { data } = response.data;
      if (data) {
        // setShouldEnable(true);
        setCepUnity(data.postal_code);
        setCep(data.postal_code);
        setSelectedState(data.uf);
        setCity(data.city);
        setBairro(data.neighborhood);
        setStreet(data.street);
        setNumberUnity(data.house_number);
      }
      setLoader(false);
    });
  }, []);

  const handleMaskPhone = (oEvent: any) => {
    const { value } = oEvent.target;
    let valuePhone = value.replace(/\D/g, '');
    valuePhone = valuePhone.replace(/^0/, '');
    if (valuePhone.length > 10) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (valuePhone.length === 7) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2$3');
    } else if (valuePhone.length > 7) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (valuePhone.length > 2) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (valuePhone.length === 0) {
      valuePhone = valuePhone.replace(/^(\d*)/, '$1');
    } else {
      valuePhone = valuePhone.replace(/^(\d*)/, '($1');
    }

    setNumber(valuePhone);
  };

  return (
    <Container>
      <SubContainer2>
        <TitleContainer>
          <Title>{store ? store.name : 'GRSoft'}</Title>
          <ImageContainer>
            {changeImg ? (
              <div>
                {thumbs}
              </div>
            ) : (
            <img
              src={store ? store.imgStore : 'https://meupedido.online/static/media/logo_dark.4bc2fe68.png'}
              alt="Logo"
              />
            ) }
            <ContainerDropZone {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Alterar a imagem</p>
            </ContainerDropZone>
          </ImageContainer>
        </TitleContainer>
        <CardsContainer>
          <Card>
            <TextContainer>
              <TitleCard>Nome da Empresa:</TitleCard>
              <Text>{store ? store.name : 'GRSoft'}</Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>CNPJ:</TitleCard>
              <Text>{store ? store.cnpj : 'N/A'}</Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>Categoria:</TitleCard>
              <Text>{store ? store.category : 'N/A'}</Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>Identificação:</TitleCard>
              <Text>{store ? store.friendlyLink : 'N/A'}</Text>
            </TextContainer>
          </Card>
          <Card>
            <TextContainer>
              <TitleCard>Contato:</TitleCard>

              <Text>{store?.whatsapp ? phone(store.whatsapp) : 'N/A'}</Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>E-mail:</TitleCard>
              <Text>{stores ? stores.email : 'N/A'}</Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>Status:</TitleCard>
              <Text>{handleStatus(store ? store.status : 'N/A')}</Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>Pedidos:</TitleCard>
              <Text>{store ? store.orderActive : 'N/A'}</Text>
            </TextContainer>
          </Card>
          <Card>
            <TextContainer>
              <TitleCard>Detalhe da entrega:</TitleCard>
              <Text>{store ? store.deliveryActive : 'N/A'}</Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>Taxa para Entrega:</TitleCard>
              <Text>N/A</Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>Tempo minimo:</TitleCard>
              <Text>
                {store ? store.minDeliveryTime : 'N/A'}
                min
              </Text>
            </TextContainer>
            <TextContainer>
              <TitleCard>Tempo máximo:</TitleCard>
              <Text>
                {store ? store.maxDeliveryTime : 'N/A'}
                min
              </Text>
            </TextContainer>
          </Card>
        </CardsContainer>
      </SubContainer2>
      <SubContainer>
        <TitleContainer>
          <Title>Gerenciador de Informações</Title>
        </TitleContainer>
        <CardsContainer>
          <Card>
            <TitleContainer>
              <TitleCard>Dados Cadastrais:</TitleCard>
            </TitleContainer>
            <InputContainer>
              <InputContainer>
                <Text>Categoria do estabelecimento:</Text>
                <Select value={categoryStore} onChange={(value) => handleInput(setCategoryStore, value)}>
                  <option value="">{}</option>
                  {category
                    ? category.Categories.map((categories) => <option value={categories}>{categories}</option>)
                    : null}
                </Select>
              </InputContainer>
              <InputContainer>
                <Text>Contato:</Text>
                <Input type="text" value={number} onChange={(value: string) => handleMaskPhone(value)} placeholder="WhatsApp" />
              </InputContainer>
            </InputContainer>
          </Card>
          {loader ? (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Loader type="ThreeDots" color={`${colors.blue}`} height={100} width={100} />
            </Grid>
          )
        : (
          <Card>
            <TitleContainer>
              <TitleCard>Dados da Unidade:</TitleCard>
            </TitleContainer>
            <InputContainer style={{ height: '45px' }}>
              <Text>CEP:</Text>
              <TextField
                disabled={shouldEnable}
                inputProps={{ maxLength: 9 }}
                style={{
                  padding: '12px',
                  width: '100%',
                  backgroundColor: '#fff',
                  height: '45px',
                }}
                value={cep}
                onChange={(event: any) => handleCepFormated(event)}
              />
            </InputContainer>
            <InputContainer>
              <InputContainer style={{ height: '45px' }}>
                {selectedState !== '' && (
                  <>
                    <Text>Estado:</Text>
                    <TextField
                      disabled
                      style={{
                        padding: '12px',
                        width: '100%',
                        backgroundColor: '#fff',
                      }}
                      value={selectedState}
                    />
                  </>
                )}
              </InputContainer>
              <InputContainer>
                {city !== '' && (
                  <>
                    <Text>Cidade:</Text>
                    <TextField
                      disabled
                      style={{ padding: '12px', width: '100%', backgroundColor: '#fff', borderRadius: '20px' }}
                      value={city}
                    />
                  </>
                )}
              </InputContainer>
            </InputContainer>
            <InputContainer>
              <InputContainer>
                {bairro !== '' && (
                  <>
                    <Text>Bairro:</Text>
                    <TextField
                      disabled
                      style={{ padding: '12px', width: '100%', backgroundColor: '#fff', borderRadius: '20px' }}
                      value={bairro}
                    />
                  </>
                )}
              </InputContainer>
              <InputContainer>
                {street !== '' && (
                  <>
                    <Text>Rua:</Text>
                    <TextField
                      disabled
                      style={{ padding: '12px', width: '100%', backgroundColor: '#fff' }}
                      value={street}
                    />
                  </>
                )}
              </InputContainer>
              <InputContainer style={{ width: '450px' }}>
                {street !== '' && (
                  <>
                    <Text>Numero:</Text>
                    <Input
                      disabled={shouldEnable}
                      type="text"
                      value={numberUnity}
                      onChange={(value: string) => handleInput(setNumberUnity, value)}
                      placeholder="Numero"
                    />
                  </>
                )}
              </InputContainer>
            </InputContainer>
            {/* <ButtonsContainer>
              {!shouldEnable && (
                <ButtonSaveContainer>
                  <Button title="Pesquisar" onClick={handleCep} />
                  {numberUnity && <Button title="Salvar" onClick={handleUnity} />}
                </ButtonSaveContainer>
              )}
            </ButtonsContainer> */}
          </Card>
            )}
          <Card>
            <TitleContainer>
              <TitleCard>Tempo médio para à entrega:</TitleCard>
            </TitleContainer>
            <SelectContainer>
              <Select value={minTime} onChange={(value) => handleInput(setMinTime, value)}>
                {time ? time.map((timeDelivery) => <option value={timeDelivery}>{timeDelivery}</option>) : null}
              </Select>
              <Text>à</Text>
              <Select value={maxTime} onChange={(value) => handleInput(setMaxTime, value)}>
                {time ? time.map((timeDelivery) => <option value={timeDelivery}>{timeDelivery}</option>) : null}
              </Select>
            </SelectContainer>
          </Card>
        </CardsContainer>
        <ButtonsContainer>
              {!shouldEnable && (
                <ButtonSaveContainer>
                  <Button title="Pesquisar" onClick={handleCep} />
                  {numberUnity && <Button title="Salvar" onClick={handleUnity} />}
                </ButtonSaveContainer>
              )}
        </ButtonsContainer>
      </SubContainer>
      {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={5000} onClose={() => window.location.reload()}>
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            Dados salvos com sucesso!
          </Alert>
        </Snackbar>
      ) : null}
      {snakOpenError ? (
        <Snackbar open={snakOpenError} autoHideDuration={5000} onClose={() => setSnackOpenError(false)}>
          <Alert onClose={() => setSnackOpenError(false)} severity="error">
            O cep informado é inválido
          </Alert>
        </Snackbar>
      ) : null}
       {snakOpenSave ? (
        <Snackbar open={snakOpenSave} autoHideDuration={5000} onClose={() => setSnackOpenSave(false)}>
          <Alert onClose={() => setSnackOpenSave(false)} severity="info">
            Pesquisar o cep informado para salvar os dados da unidade
          </Alert>
        </Snackbar>
      ) : null}
    </Container>
  );
};

export default Profile;
