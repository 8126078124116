import React from 'react';
import Button from '../Button';
import { TitleContainer, Title } from './styles';

interface IPropsDTO {
  title?: string;
  button?: boolean;
  onClick?: any;
}
const CardBase = ({ title, button, onClick }: IPropsDTO) => (
  <TitleContainer>
    <Title>{title}</Title>
    {button ? <Button title="Todos" onClick={onClick} /> : null}
  </TitleContainer>
);

export default CardBase;
