import React from 'react';

import { Provider } from 'react-redux';
import Routes from './routes/index';
import { store } from './store';
import { GlobalStyle } from './styles';

const App: React.FC = () => (
  <>
    <Provider store={store}>
      <GlobalStyle />
      <Routes />
    </Provider>
  </>
);

export default App;
