/* eslint-disable max-len */
import React from 'react';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../InputBase';
import Button from '../Button';
import { IComplementDTO } from '../../interfaces/IProductDTO';
import mMoeda from '../../util/float2moeda';
import {
  Container,
  CardsContainer,
  Card,
  InputContainer,
  SelectContainer,
  Text,
  Select,
  ContainerGroup,
} from './styles';

const ordenations = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];
const CardComplement = ({
  name,
  maximum,
  minimum,
  price,
  ordernation,
  index,
  externalCode,
  handleDeleteIncrement,
}: IComplementDTO) => {
  const dispatch = useDispatch();
  const incrementChange: any = useSelector((state: any) => state.incrementData);

  const handleChangeName = (value: any, indexIncrement: any) => {
    const increment = [...incrementChange];
    increment[indexIncrement].name = value.target.value;
    dispatch({ type: 'ADD_INCREMENT', increment });
  };

  const handleChangeQuantityMax = (value: any, indexIncrement: any) => {
    const increment = [...incrementChange];
    increment[indexIncrement].maximum = value.target.value;
    dispatch({ type: 'ADD_INCREMENT', increment });
  };

  const handleChangeQuantityMin = (value: any, indexIncrement: any) => {
    const increment = [...incrementChange];
    increment[indexIncrement].minimum = value.target.value;
    dispatch({ type: 'ADD_INCREMENT', increment });
  };

  const handleChangePrice = (value: any, indexIncrement: any) => {
    const increment = [...incrementChange];
    increment[indexIncrement].price = mMoeda(value.target.value);
    dispatch({ type: 'ADD_INCREMENT', increment });
  };

  const handleChangeOrdenation = (value: any, indexIncrement: any) => {
    const increment = [...incrementChange];
    increment[indexIncrement].ordernation = value.target.value;
    dispatch({ type: 'ADD_INCREMENT', increment });
  };

  const handleAddingExternalCode = (value: any, indexIncrement: any) => {
    const increment = [...incrementChange];
    increment[indexIncrement].external_code = value.target.value;
    dispatch({ type: 'ADD_INCREMENT', increment });
  };

  return (
    <Container>
      <CardsContainer>
        <Card>
          <ContainerGroup>
            <InputContainer>
              <InputContainer>
                <Text>Nome:</Text>
                <Input
                  type="text"
                  placeholder=""
                  value={name}
                  onChange={(value: any) => handleChangeName(value, index)}
                />
              </InputContainer>
              <InputContainer style={{ width: '5%' }}>
                <Button title="" onClick={handleDeleteIncrement} icon={<DeleteOutline />} />
              </InputContainer>
            </InputContainer>
          </ContainerGroup>
          <ContainerGroup>
            <InputContainer>
              <InputContainer>
                <Text>Mínima:</Text>
                <Input
                  type="text"
                  value={minimum}
                  placeholder="Quantidade mínima do produto"
                  onChange={(value: any) => handleChangeQuantityMin(value, index)}
                />
              </InputContainer>
              <InputContainer>
                <Text>Máxima:</Text>
                <Input
                  type="text"
                  value={maximum}
                  placeholder="Quantidade máxima do produto"
                  onChange={(value: any) => handleChangeQuantityMax(value, index)}
                />
              </InputContainer>
              <InputContainer>
                <Text>Preço:</Text>
                <Input
                  type="text"
                  value={price}
                  placeholder="R$"
                  onChange={(value: any) => handleChangePrice(value, index)}
                />
              </InputContainer>
            </InputContainer>
          </ContainerGroup>
          <InputContainer>
            <SelectContainer>
              <Text>Ordem de Apresentação:</Text>
              <Select value={ordernation} onChange={(value: any) => handleChangeOrdenation(value, index)}>
                {ordenations?.map((ordenationP) => (
                  <option value={ordenationP}>{ordenationP}</option>
                ))}
              </Select>
            </SelectContainer>
            <InputContainer>
              <Text>Código:</Text>
              <Input
                type="number"
                value={externalCode}
                placeholder="Código do item"
                onChange={(value: any) => handleAddingExternalCode(value, index)}
              />
            </InputContainer>
          </InputContainer>
        </Card>
      </CardsContainer>
    </Container>
  );
};

export default CardComplement;
