import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  height: 100vh;
  padding-left: 16.875rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const SubContainer = styled.div`
  display: flex;
  width: calc(100% - 50px);
  height: auto;
  padding: 25px;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const Title = styled.span`
  color: #636363;
  font-weight: 580;
  font-size: 24px;
  text-align: left;
`;

export const TableContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
