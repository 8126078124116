/* eslint-disable no-unused-expressions */

import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Snackbar } from '@material-ui/core';
import moment from 'moment';
import { Lens } from '@material-ui/icons';
import { Howl, Howler } from 'howler';
import Alert from '@material-ui/lab/Alert';
import {
  Container,
  Approved,
  Concluded,
  Received,
  Traffic,
  Canceled,
  AllOrders,
  ButtonContainer,
  ReadyTraffic,
} from './styles';
import api from '../../service/api';
import IconButton from '../IconButton';
import OrderManager from '../OrderManager';
// @ts-ignore
import ringNotification from '../../assets/bell.mp3';
import Button from '../Button';

const TableOrder = () => {
  const [orders, setOrders] = useState();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [id, setId] = useState('');
  const [token] = useState(localStorage.getItem('token'));
  const [pooling, setPooling] = useState(true);
  const [open, setOpen] = useState<boolean>();
  const [searchName, setSearchName] = useState<string>();
  const [filterName, setFilterName] = useState<string>();
  const [hasFilter, setHasFilter] = useState<boolean>(false);
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  function handleClick(orderId: string) {
    setModalVisible(true);
    setId(orderId);
  }

  function handleStatusDelivery(status: string, idOrder: string) {
    switch (status) {
      case 'RECEIVED':
        return (
          <Received>
            <IconButton icon={<Lens />} onClick={() => handleClick(idOrder)} />
          </Received>
        );
      case 'APPROVED':
        return (
          <Approved>
            <IconButton icon={<Lens />} onClick={() => handleClick(idOrder)} />
          </Approved>
        );
      case 'READY_TRAFFIC':
        return (
          <ReadyTraffic>
            <IconButton icon={<Lens />} onClick={() => handleClick(idOrder)} />
          </ReadyTraffic>
        );
      case 'TRAFFIC':
        return (
          <Traffic>
            <IconButton icon={<Lens />} onClick={() => handleClick(idOrder)} />
          </Traffic>
        );
      case 'CONCLUDED':
        return (
          <Concluded>
            <IconButton icon={<Lens />} onClick={() => handleClick(idOrder)} />
          </Concluded>
        );
      case 'CANCELED':
        return (
          <Canceled>
            <IconButton icon={<Lens />} onClick={() => handleClick(idOrder)} />
          </Canceled>
        );
      default:
        return '';
    }
  }

  function handleDeliveryType(type: string) {
    switch (type) {
      case 'E':
        return 'ENTREGAR';
      case 'R':
        return 'RETIRAR';
      case 'L':
        return 'LOCAL';
      default:
        return '';
    }
  }
  const SoundPlay = (src: any) => {
    const sound = new Howl({ src });
    sound.duration(0.2);
    sound.loop(false);
    sound.play();
  };

  setTimeout(() => setPooling(!pooling), 20000);
  const handleOrderData = () => {
    api.get('/orders/pooling', config);
    if (hasFilter) {
      // @ts-ignore
      handleFilterOrder(filterName);
    } else {
      api.get('/orders/', config).then((response) => {
        setOrders(response.data);
        if (response.data.shouldRing) {
          SoundPlay(ringNotification);
        }
      });
    }
  };

  useEffect(() => {
    handleOrderData();
  }, [pooling]);
  Howler.volume(1.0);

  function handleTypeSearch(type: string) {
    switch (type) {
      case 'RECEIVED':
        return ' Pendente de Confirmação';
      case 'APPROVED':
        return ' Em Produção';
      case 'READY_TRAFFIC':
        return ' Pronto para retirada';
      case 'TRAFFIC':
        return ' Saiu para entrega';
      case 'CONCLUDED':
        return ' Entregue';
      case 'CANCELED':
        return ' Cancelado';
      default:
        return '';
    }
  }

  const handleFilterOrder = (filter: string) => {
    if (filter !== 'ALL') {
      setFilterName(filter);
      api.get(`/orders/status/${filter}`, config).then((response: any) => {
        setOrders(response);
        setHasFilter(true);
        if (response.data.length === 0) {
          const type = handleTypeSearch(filter);
          setSearchName(type);
          setOpen(true);
        }
      });
    } else {
      setHasFilter(false);
      api.get('/orders/', config).then((response) => {
        setOrders(response.data);
      });
    }
  };

  return (
    <>
      <ButtonContainer>
        <AllOrders>
          <Button icon={<Lens />} onClick={() => handleFilterOrder('ALL')} title="Listar todos" />
        </AllOrders>
        <Received>
          <Button icon={<Lens />} onClick={() => handleFilterOrder('RECEIVED')} title="Pendente de Confirmação" />
        </Received>
        <Approved>
          <Button icon={<Lens />} onClick={() => handleFilterOrder('APPROVED')} title="Em produção" />
        </Approved>
        <ReadyTraffic>
          <Button icon={<Lens />} onClick={() => handleFilterOrder('READY_TRAFFIC')} title="Pronto para retirada" />
        </ReadyTraffic>
        <Traffic>
          <Button icon={<Lens />} onClick={() => handleFilterOrder('TRAFFIC')} title="Saiu para entrega" />
        </Traffic>
        <Concluded>
          <Button icon={<Lens />} onClick={() => handleFilterOrder('CONCLUDED')} title="Entregue" />
        </Concluded>
        <Canceled>
          <Button icon={<Lens />} onClick={() => handleFilterOrder('CANCELED')} title="Cancelado" />
        </Canceled>
      </ButtonContainer>
      <Container>
        <Table size="medium" style={{ minHeight: '25vh' }}>
          <TableHead>
            <TableRow>
              <TableCell>Referência</TableCell>
              <TableCell>Pagamento</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Total (R$)</TableCell>
              <TableCell>Data e Hora do Pedido</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders // @ts-ignore
              ? orders.data?.map((order) => (
                  <TableRow key={order.shortReference}>
                    <TableCell scope="row">{order.shortReference}</TableCell>

                    <TableCell>{order.paymentForm}</TableCell>

                    <TableCell>{handleDeliveryType(order.deliveryType)}</TableCell>
                    <TableCell>
                      R$
                      {order.total}
                    </TableCell>

                    <TableCell>{moment(order.createdDate).format('DD-MM-YYYY, h:mm:ss a')}</TableCell>

                    <TableCell>{handleStatusDelivery(order.status, order.id)}</TableCell>
                  </TableRow>
                ))
              : null}
            {open ? (
              <Snackbar open={open} autoHideDuration={5000} onClose={() => setOpen(false)}>
                <Alert severity="warning">
                  Não foi encontrado pedidos com status:
                  {searchName}
                </Alert>
              </Snackbar>
            ) : null}
          </TableBody>
        </Table>
        {modalVisible ? (
          <OrderManager
            id={id}
            onClose={() => {
              setModalVisible(false);
            }}
          />
        ) : null}
      </Container>
    </>
  );
};
export default TableOrder;
