import React, { useState } from 'react';
import { AddAlertOutlined } from '@material-ui/icons';
import CardButton from '../BaseCardButton';
import CardReminder from '../CardReminder';
import { Container, SubContainer } from './styles';

const ModalBodyReminder = () => {
  const [newReminder, setNewReminder] = useState<boolean>(false);
  return (
    <Container>
      <SubContainer>
        <CardButton icon={<AddAlertOutlined />} title="Adicionar Lembrete" onClick={() => setNewReminder(true)} />
      </SubContainer>
      {newReminder}
      <CardReminder title="Adicionando Lembretes" text="10:55" />
    </Container>
  );
};

export default ModalBodyReminder;
