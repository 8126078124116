import styled from 'styled-components';
import { colors } from '../../style/styles';

export const Container = styled.div`
  display: flex;
  background-color: #f0f0fa;
  height: auto;
  width: 100%;
  padding-left: 16.875rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .react-tabs__tab-list {
    width: 90%;
    border-bottom: 1px solid #a6a6a6;
    margin: 0 0 10px;
    padding: 0;
    /* text-align: center; */
  }

  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0px 15px;
    cursor: pointer;
    font-weight: 500;
  }

  .react-tabs__tab:hover:not(.react-tabs__tab--selected) {
    border-bottom: 1px solid #a6a6a6;
    border-width: 2px;
  }

  .react-tabs__tab--selected {
    color: ${colors.blue};
    border-bottom: 1px solid ${colors.blue};
    border-width: 2px;
  }
  .react-tabs__tab-panel {
    display: none;
  }
  .react-tabs__tab-panel--selected {
    display: block;
  }
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
`;

export const ContainerCategory = styled.div`
  display: flex;
  position: absolute;
  width: 80%;
  height: 100vh;
  justify-content: flex-end;
  z-index: 10;
`;

export const SubContainer = styled.div`
  display: flex;
  position: fixed;
  margin: 0px;
  width: 40%;
  height: 100vh;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
`;
export const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
`;

export const Title = styled.span`
  color: #636363;
  width: 100%;
  font-weight: 560;
  font-size: 18px;
  text-align: left;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 50px;
  margin-bottom: 3%;
  margin-right: 5%;
  right: 50px;
`;

export const ContainerTabPanelDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardsContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px;
  margin: 15px 5px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c4c4c4;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 14px);
  padding: 15px;

  input {
    width: 100% !important;
    border: 1px solid #636363;
  }
`;

export const DropzoneContainer = styled.div`
  display: block;
  width: calc(100% - 30px);
  height: auto;
`;

export const DropzoneSubContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  margin: 0px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 135px;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  border: 1px solid #c4c4c4;

  img {
    width: 245px;
    height: 130px;
    object-fit: cover;
  }
`;

export const ButtonsContainerCancel = styled.div`
  margin-right: 1%;
  span {
    color: ${colors.blue};
  }
  button {
    padding: 15px 15px;
    border-radius: 10px;
    border: solid 1px ${colors.blue};
    background-color: #ffffff !important;
    cursor: pointer;
  }
`;

export const ButtonsContainerContinue = styled.div`
  span {
    color: #ffffff;
  }
  button {
    padding: 15px 15px;
    border-radius: 10px;
    background-color: ${colors.blue} !important;
    cursor: pointer;
  }
  button:disabled {
    background-color: #c0c0c0 !important;
    cursor: default;
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

export const CategoriesBaseContainer = styled.div`
  display: flex;
  width: 80%;
  height: auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

export const CategoriesBaseHeader = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  margin-top: 10%;
  button {
    width: auto;
    height: 60%;
    border-radius: 8px;
    background-color: ${colors.blue} !important;
    transition: 0.8s;
    cursor: pointer;
  }
  button:hover {
    outline: transparent;
    background-color: ${colors.hoverBlue} !important;
  }
`;

export const TabsContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0px;
`;
