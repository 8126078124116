import styled from 'styled-components';

export const DrawerContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 9999999;
  left: 0px;
  top: 0px;
  width: 275px;
  height: 100vh;

  .pro-sidebar {
    color: black;
  }

  .pro-sidebar > .pro-sidebar-inner {
    background: #ffff;
    height: 100%;
    position: relative;
    z-index: 101;
  }

  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: #ffff;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 95%;
  flex-direction: column;
  background-color: #f7f7f8;

  svg {
    align-items: center !important;
    text-align: left !important;
    background-color: #fff !important;
  }

  svg:hover {
    color: #0000ff !important;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #000000;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  top: 0px;
  left: 0px;
  position: relative;

  img {
    border-style: none;
    width: auto;
    height: 100px;
    margin: 0px 15px;
  }

  svg {
    margin: 0px 5px;
    color: #636363 !important;
    background-color: #fff !important;
  }
`;

export const SubContainer = styled.div`
  overflow: auto;
  height: 100%;

  button {
    background-color: #f7f7f8 !important;
    justify-content: space-between !important;

    svg {
      margin: 0px !important;
    }
  }
`;

export const BodyList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0px;
  width: 100%;
  margin: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f8;
  position: relative;
`;

export const BodyListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  background-color: #f7f7f8;
  position: relative;

  button {
    background-color: #f7f7f8 !important;

    svg {
      margin: 0px !important;
    }
  }
`;
