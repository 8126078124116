import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dcdcde !important;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px;
  background-color: #fff;
  border: none;

  &:hover {
    background-color: #f7f7f8;

    svg {
      color: #0000ff !important;
      background-color: #f7f7f8;
    }

    span {
      color: #0000ff;
    }
  }

  span {
    margin: 0px 5px;
    font-weight: 600;
    color: #808080;
    width: 100%;
  }

  svg {
    margin: 0px 5px;
    color: #dcdcde !important;
  }
`;
