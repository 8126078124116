/* eslint-disable max-len */
import React, { useState } from 'react';
import { Close } from '@material-ui/icons';
import IconButton from '../IconButton';
import { ModalContainer, Container, TitleContainer } from './styles';
import BodyInvite from '../ModalBodyInvite';
import BodyReminder from '../ModalBodyReminder';
import BodyNote from '../ModalBodyNote';
import BodyToDo from '../ModalBodyToDo';

interface IPropsDTO {
  title: string;
  onClose: any;
  invite?: boolean;
  reminder?: boolean;
  note?: boolean;
  toDo?: boolean;
}

const ModalAside = ({ title, onClose, toDo = false, invite = false, reminder = false, note = false }: IPropsDTO) => {
  const [toDoVisible] = useState<boolean>(toDo);
  const [inviteVisible] = useState<boolean>(invite);
  const [reminderVisible] = useState<boolean>(reminder);
  const [noteVisible] = useState<boolean>(note);

  return (
    <ModalContainer>
      <Container>
        <TitleContainer>
          <span>{title}</span>
          <IconButton icon={<Close />} onClick={onClose} />
        </TitleContainer>
        {toDoVisible ? <BodyToDo /> : null}
        {inviteVisible ? <BodyInvite /> : null}
        {reminderVisible ? <BodyReminder /> : null}
        {noteVisible ? <BodyNote /> : null}
      </Container>
    </ModalContainer>
  );
};

export default ModalAside;
