/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { Container, SubContainer } from './styles';
import IconButton from '../IconButton';

interface IPropsDTO {
  onClick?: any;
  expand?: boolean;
  name?: string;
  iconExpand?: any;
  id?: string;
}

const CatalogButton = ({ onClick, name, expand = false, iconExpand, id }: IPropsDTO) => {
  const [expandVisible] = useState<boolean>(expand);
  return (
    <Container name={name} onClick={onClick} id={id}>
      {expandVisible ? (
        <SubContainer>
          <IconButton icon={iconExpand} />
        </SubContainer>
      ) : null}
    </Container>
  );
};

export default CatalogButton;
