import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 60px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 200px;
  align-items: center;
  justify-content: center;
  padding: 35px;
`;

export const Wrapped = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.span`
  width: 100%;
  color: #636363;
  font-weight: 580;
  font-size: 18px !important;
  text-align: left;
  margin-bottom: 80px;
`;
