/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import api from '../../service/api';
import { IStoreDTO, IStoreParamsDTO } from '../../interfaces/IStoreDTO';
import CardBase from '../CardBase';
import CardGraphic from '../CardGraphicDashboard';
import TableOrdersRequest from '../TableOrdersRequest';
import CardInformation from '../CardGeneralInformation';
import * as C from './styles';
import cash from '../../util/cash';

const Dashboard = () => {
  const history = useHistory();
  const [storeArray] = useState(localStorage.getItem('authStore'));
  const [token] = useState(localStorage.getItem('token'));
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const [store, setStore] = useState<IStoreDTO>();
  const [currentTotalOrdersValue, setCurrentTotalOrdersValue] = useState<any>(0);
  const [currentTotalOrders, setCurrentTotalOrders] = useState<any>(0);
  const [previousTotalOrdersValue, setPreviousTotalOrdersValue] = useState<any>(0);
  const [previousTotalOrders, setPreviousTotalOrders] = useState<any>(0);
  const [viewsCounter, setViewsCounter] = useState<any>(0);
  const [newUsers, setNewUsers] = useState<any>(0);

  let stores: IStoreParamsDTO;
  function handleStore() {
    if (storeArray !== null) stores = JSON.parse(storeArray);
  }

  function handleClick(route: string) {
    history.push(`/${route}`);
  }

  useEffect(() => {
    handleStore();
  }, []);

  useEffect(() => {
    api.get(`stores/${stores?.store_name}`).then((response) => {
      setStore(response.data);
    });
  }, []);

  useEffect(() => {
    api.post('orders/info/', { storeId: stores?.store_name }, config).then((response) => {
      response.data.current.forEach((line: any) => {
        line.totalOrdersValue[0].total === null
          ? setCurrentTotalOrdersValue(0)
          : setCurrentTotalOrdersValue(line.totalOrdersValue[0].total);
      });
      response.data.current.forEach((line: any) => {
        line.totalOrders[0].total === null
          ? setCurrentTotalOrders('0')
          : setCurrentTotalOrders(line.totalOrders[0].total);
      });

      response.data.previous.forEach((line: any) => {
        line.totalOrdersValue[0].total === null
          ? setPreviousTotalOrdersValue(0)
          : setPreviousTotalOrdersValue(line.totalOrdersValue[0].total);
      });
      response.data.previous.forEach((line: any) => {
        line.totalOrders[0].total === null
          ? setPreviousTotalOrders('0')
          : setPreviousTotalOrders(line.totalOrders[0].total);
      });
    });
  }, []);

  useEffect(() => {
    const storeName = { store: stores?.store_name };
    api.post('views', storeName).then((response) => {
      console.log(response);
      setViewsCounter(response.data);
    });
  }, []);

  useEffect(() => {
    const storeName = { storeName: stores?.store_name };
    api.post('new-user', storeName).then((response) => {
      console.log(response);
      setNewUsers(response.data);
    });
  }, []);

  return (
    <C.Container>
      <C.Content>
        <C.TextContainer>
          <C.Title>{`Seja bem-vindo, ${store?.name}`}</C.Title>
        </C.TextContainer>

        <C.CardsContainer>
          <C.CardsContent>
            <CardBase title="Informações Gerais" />
            <C.InnerCards>
              <Grid container direction="row" justifyContent="center">
                <CardInformation
                  cardTitle="Total de vendas"
                  symbolType=""
                  type="sell"
                  price={cash(currentTotalOrdersValue)}
                  income={cash(previousTotalOrdersValue)}
                />
                <CardInformation
                  cardTitle="Total de Pedidos"
                  symbolType=""
                  type="info"
                  price={currentTotalOrders}
                  income={previousTotalOrders}
                />

                <CardInformation
                  type="visitors"
                  symbolType=""
                  cardTitle="Visitas Hoje"
                  price={viewsCounter}
                  income=""
                />
                <CardInformation type="newusers" symbolType="" cardTitle="Novos usuários" price={newUsers} income="" />
              </Grid>
            </C.InnerCards>
          </C.CardsContent>
        </C.CardsContainer>
        <C.OrdersContainer>
          <C.OrdersContent>
            <Grid container direction="row" justifyContent="center">
              <CardBase onClick={() => handleClick('orders')} button title="Pedidos Solicitados Recentemente" />
              <TableOrdersRequest />
            </Grid>
          </C.OrdersContent>
        </C.OrdersContainer>

        <C.GraphContainer>
          <C.CardsContent>
            <Grid container direction="row" justifyContent="center">
              <C.TitleChart>Faturamento dos ultimos 30 Dias</C.TitleChart>
              <C.Line />

              <CardGraphic />
            </Grid>
          </C.CardsContent>
        </C.GraphContainer>
      </C.Content>
    </C.Container>
  );
};
export default Dashboard;
