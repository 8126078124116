import React from 'react';
import { Container } from './styles';

interface IPropsDTO {
  title: string;
  onClick: any;
  icon?: any;
  disabled?: boolean;
}

const Button = ({ title, onClick, icon, disabled = false }: IPropsDTO) => (
  <Container disabled={disabled} onClick={onClick}>
    {icon}
    <span>{title}</span>
  </Container>
);

export default Button;
