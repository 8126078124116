import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: auto;
`;

export const ReceivedContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 35px;
  box-shadow: 1px 1px 5px #e5e5e5;

  svg {
    color: #ff706b;
  }
`;

export const ApprovedContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 35px;
  box-shadow: 1px 1px 5px #e5e5e5;

  svg {
    color: #ffaa00;
  }
`;

export const TrafficContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 35px;
  box-shadow: 1px 1px 5px #e5e5e5;

  svg {
    color: #b9d964;
  }
`;

export const ConcludedContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 35px;
  box-shadow: 1px 1px 5px #e5e5e5;

  svg {
    color: #0082c1;
  }
`;

export const CanceledContainer = styled.div`
  display: flex;
  width: 65vw;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 35px;
  box-shadow: 1px 1px 5px #e5e5e5;

  svg {
    color: #181818;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  padding: 20px 5px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;

  svg {
    width: 15px !important;
    color: #3e3e3e !important;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px 0px;
`;

export const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 10px);
  padding: 0px;
`;

export const HeaderTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #dcdcde;
  width: 100%;

  svg {
    color: transparent !important;
  }
`;

export const FooterTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* border-top: 2px solid #dcdcde; */
  border-bottom: 2px solid #dcdcde;
  width: 100%;
  span {
    padding: 20px 7px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 5px);
  height: auto;
  padding: 2.5px 0px;
`;

export const Title = styled.span`
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  text-align: left;
  align-items: center;
  color: #636363;
`;

export const Text = styled.span`
  display: flex;
  text-align: left;
  font-size: 12px !important;
  align-items: center;
  color: #636363;
  margin-left: 10px;
`;

export const TextSpecial = styled.span`
  display: flex;
  text-align: left;
  font-size: 14px !important;
  align-items: center;
  margin-left: 10px;
  color: #b9d964 !important;
`;
