/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import React from 'react';
import { Container } from './styles';

interface IPropsDTO {
  icon: any;
  onClick?: any;
}
const IconButton = ({ icon, onClick }: IPropsDTO) => <Container onClick={onClick}>{icon}</Container>;

export default IconButton;
