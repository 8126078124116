import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, ImageContainer, Wrapper, TypingDemo, ButtonHome } from './styles';
import Button from '../Button';

const PageUnderConstruction = () => {
  const history = useHistory();

  function handleButtonHome(route: string) {
    history.push(`/${route}`);
  }

  return (
    <Container>
      <Wrapper>
        <TypingDemo>Página em construção...</TypingDemo>
      </Wrapper>
      <ImageContainer>
        <img src="https://i.imgur.com/IrihDiC.jpg" style={{ opacity: '11.0%', height: '100%', width: '100%' }} alt="" />
        <ButtonHome>
          <Button onClick={() => handleButtonHome('dashboard')} title="Voltar para dashboard" />
        </ButtonHome>
      </ImageContainer>
    </Container>
  );
};
export default PageUnderConstruction;
