import styled from 'styled-components';

export const Container = styled.body`
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
`;

export const Main = styled.main`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fefefe;
`;

export const NavbarContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 1;
`;
