import jwt from 'jsonwebtoken';
import { isExpired } from 'react-jwt';

export const TOKEN_KEY = 'token';
export const TOKEN_STORE = 'authStore';

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_STORE);
};

// @ts-ignore
const verifyToken = jwt.decode(localStorage.getItem(TOKEN_KEY));
// @ts-ignore
const isMyTokenExpired = isExpired(localStorage.getItem(TOKEN_KEY));
if (!verifyToken || isMyTokenExpired) {
  logout();
}

export const VerifyToken = () => {
  // @ts-ignore
  const isTokenExpired = isExpired(localStorage.getItem(TOKEN_KEY));
  // @ts-ignore
  const isVerifyToken = jwt.decode(localStorage.getItem(TOKEN_KEY));
  if (!isVerifyToken || isTokenExpired) {
    logout();
    return null;
  }
  return localStorage.getItem(TOKEN_KEY);
};

export const isAuthenticated = () => VerifyToken();

export const Login = (data: any) => {
  const { token } = data;
  const { authStore } = data;
  localStorage.setItem(TOKEN_STORE, JSON.stringify(authStore));
  localStorage.setItem(TOKEN_KEY, token);
};
