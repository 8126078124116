import styled from 'styled-components';
import { colors } from '../../style/styles';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  svg {
    cursor: pointer;
    color: ${colors.blue};
  }
`;
