import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 25px;
  height: 0px;
  width: 0px;
  background-color: #000;

  svg {
    background-color: #fff;
    height: 30px;
    width: 30px;
    border-style: none;
    border-radius: 15px;
    margin: 0px 10px;
    color: #3e3e3e;
  }
`;
