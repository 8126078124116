import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100% - 60px);
  height: auto;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SubContainer = styled.div`
  display: flex;
  width: calc(100% - 50px);
  height: auto;
  padding: 0px 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 0 1px #636363;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const Title = styled.span`
  color: #636363;
  font-weight: 580;
  font-size: 24px;
  text-align: left;
`;

export const TableContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
