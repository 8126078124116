import React from 'react';
import { Edit, Delete, Share } from '@material-ui/icons';
import { Container, TitleContainer, BodyContainer, FooterContainer } from './styles';
import CardButton from '../BaseCardButton';

interface IPropsDTO {
  title: string;
  text: string;
  onClickEdit?: any;
  onClickDelete?: any;
  onClickShare?: any;
}

const CardNote = ({ title, text, onClickEdit, onClickDelete, onClickShare }: IPropsDTO) => (
  <Container>
    <TitleContainer>
      <span>{title}</span>
    </TitleContainer>
    <BodyContainer>
      <span>{text}</span>
    </BodyContainer>
    <FooterContainer>
      <CardButton onClick={onClickShare} icon={<Share />} title="Compartilhar" />
      <CardButton onClick={onClickEdit} icon={<Edit />} title="Editar" />
      <CardButton onClick={onClickDelete} icon={<Delete />} title="Excluir" />
    </FooterContainer>
  </Container>
);

export default CardNote;
