import React from 'react';
import { Container, Main, NavbarContainer } from './styles';
import NavBar from '../../../components/NavBar';
import CatalogBody from '../../../components/Catalog';

const Catalog = () => (
  <Container>
    <Main>
      <CatalogBody />
    </Main>
    <NavbarContainer>
      <NavBar />
    </NavbarContainer>
  </Container>
);

export default Catalog;
