import styled from 'styled-components';

export const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999999;
  background-color: rgba(255, 255, 255, 1);
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
`;

export const Container = styled.div`
  display: flex;
  height: auto;
  margin-left: 10vw;
  /* padding-left: 16.875rem; */
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 0.3px;
  border-style: solid;
  border-radius: 1%;
  border-color: #3e3e3e;
  box-shadow: 1px #3e3e3e;
`;

export const SubContainer = styled.div`
  display: flex;
  width: calc(100% - 50px);
  background-color: '#fefefe';
  height: auto;
  padding: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  color: #636363;
  font-weight: 580;
  font-size: 24px;
  text-align: left;
`;

export const OrderContainer = styled.div`
  display: flex;
  width: 65vw;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

export const MessageContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: calc(100% - 120px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;

  span {
    text-align: center;
    color: #636363;
    font-size: 22px;
    margin-bottom: 15px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    text-align: center;
    color: #fff !important;
    font-size: 18px !important;
    margin: 0px !important;
    font-weight: 400 !important;
  }
`;

export const ButtonCancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;

  button {
    background-color: #ff706b !important;
    padding: 20px 45px;

    &:hover {
      background-color: #ff4c43 !important;
    }
  }
`;
export const ButtonConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;

  button {
    background-color: #b9d964 !important;
    padding: 20px 45px;

    &:hover {
      background-color: #c0e855 !important;
    }
  }
`;
