import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  /* min-height: 100vh; */
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 2px 2px 5px #c4c4c4;

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  tr {
    border-bottom: 1px solid #dcdcde;
    justify-content: space-between !important;
    margin: 5px;
    width: 100%;

    th {
      padding: 5px;
      border: none !important;
      color: #3e3e3e;
      font-weight: 550;
      font-size: 14px;
    }

    td {
      padding: 5px 20px;
      font-size: 10px;
      border: none !important;
      color: #636363;
    }
  }
`;

export const Received = styled.div`
  svg {
    color: #ff706b !important;
  }
`;

export const Approved = styled.div`
  svg {
    color: #ffaa00 !important;
  }
`;

export const ReadyTraffic = styled.div`
  svg {
    color: #7b68ee !important;
  }
`;

export const Traffic = styled.div`
  svg {
    color: #b9d964 !important;
  }
`;

export const Concluded = styled.div`
  svg {
    color: #0082c1 !important;
  }
`;

export const Canceled = styled.div`
  svg {
    color: #181818 !important;
  }
`;
export const AllOrders = styled.div`
  svg {
    color: #dcdcdc !important;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 10px;
  button {
    background-color: #ffffff !important;
    padding: 5px;
    &:hover {
      background-color: #f0f8ff !important;
    }
    span {
      color: #636363;
      font-size: 14px;
    }

    svg {
      margin: 0px 10px;
      height: 25px;
      width: 25px;
    }
  }
`;
