/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import { Container, SubContainer } from './styles';
// import { IOrderDTO } from '../../interfaces/IOrderDTO';
import api from '../../service/api';

const TableOrdersRequest = () => {
  const [orders, setOrders] = useState();

  const token = localStorage.getItem('token');
  let parsedToken = '';

  if (token) {
    parsedToken = token;
  }
  const config = {
    headers: { Authorization: 'Bearer ' + `${parsedToken}` },
  };

  useEffect(() => {
    const config = {
      headers: {
        authorization: 'Bearer ' + `${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    api.get('/orders/pooling', config).then((response) => {
      setOrders(response.data);
    });
  }, []);

  function handleStatus(status: string) {
    switch (status) {
      case 'RECEIVED':
        return 'Realizado';

      case 'APPROVED':
        return 'Confirmado';

      case 'READY_TRAFFIC':
        return 'Aguardando entrgador';

      case 'TRAFFIC':
        return 'Saiu para entrega';

      case 'CONCLUDED':
        return 'Pedido Finalizado';
      default:
        return '';
    }
  }

  return (
    <Container>
      <SubContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>ID (Ref)</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Tempo de Espera</TableCell>
              <TableCell>Pedido Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* @ts-ignore */}
            {orders?.length
              ? // @ts-ignore
                orders.map((order) => (
                  <TableRow key={order.shortReference}>
                    <TableCell scope="row">{order.shortReference}</TableCell>
                    <TableCell>{order.total}</TableCell>
                    <TableCell>{moment(order.createdDate).startOf('hour').fromNow()}</TableCell>
                    <TableCell>{handleStatus(order.status)}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </SubContainer>
    </Container>
  );
};

export default TableOrdersRequest;
