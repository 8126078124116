import React from 'react';
import { GroupAddOutlined } from '@material-ui/icons';
import InputBase from '../InputBase';
import Button from '../Button';
import { Container } from './styles';
import IconButton from '../IconButton';

const ModalBodyInvite = () => (
  <Container>
    <IconButton icon={<GroupAddOutlined />} />
    <span>Convide membros de equipe</span>
    <InputBase type="text" placeholder="e-mail" onChange={{}} />
    <Button onClick={{}} title="Convidar" />
  </Container>
);

export default ModalBodyInvite;
