import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  svg {
    color: #fff;
    width: 30px;
    height: 30px;
  }

  span {
    color: #636363;
  }
`;

export const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  fill: #273840;
  background-color: #718f2f;
`;

export const ImageContainer = styled.div`
  width: fit-content;
  height: auto;
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  background-color: #fff;
  box-shadow: 0 0 1px #3e3e3e;
  height: 100%;
  width: 100%;

  button {
    background-color: #273840 !important;
    padding: 20px 25px;

    &:hover {
      background-color: #2738 !important;
    }

    span {
      color: #648043 !important;
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
