import styled from 'styled-components';
// @ts-ignore
import { colors } from '../../style/styles';

export const Container = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
  /* background-color: rgba(0, 0, 0, 0.5); */

  .react-tabs__tab-list {
    width: 90%;
    border-bottom: 1px solid #a6a6a6;
    margin: 0 0 10px;
    padding: 0;
    /* text-align: center; */
  }

  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0px 15px;
    cursor: pointer;
    font-weight: 500;
  }

  .react-tabs__tab:hover:not(.react-tabs__tab--selected) {
    border-bottom: 1px solid #a6a6a6;
    border-width: 2px;
  }

  .react-tabs__tab--selected {
    color: ${colors.blue};
    border-bottom: 1px solid ${colors.blue};
    border-width: 2px;
  }
  .react-tabs__tab-panel {
    display: none;
  }
  .react-tabs__tab-panel--selected {
    display: block;
  }
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  position: fixed;
  margin: 0px;
  width: 40%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
`;

export const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
`;

export const Title = styled.span`
  color: #636363;
  width: 100%;
  font-weight: 580;
  font-size: 24px;
  text-align: left;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 50px;
  margin-bottom: 3%;
  margin-right: 5%;
  right: 50px;
`;

export const ButtonsContainerCancel = styled.div`
  margin-right: 2%;
  span {
    color: ${colors.blue};
  }
  button {
    padding: 15px 15px;
    border-radius: 10px;
    border: solid 1px ${colors.blue};
    background-color: #ffffff !important;
    cursor: pointer;
  }
`;

export const ButtonsContainerContinue = styled.div`
  span {
    color: #ffffff;
  }
  button {
    padding: 15px 15px;
    border-radius: 10px;
    background-color: ${colors.blue} !important;
    cursor: pointer;
  }
  button:disabled {
    background-color: #c0c0c0 !important;
    cursor: default;
  }
`;

export const ContainerTabPanelDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardsContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px;
  margin: 15px 5px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c4c4c4;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 14px);
  padding: 7px;

  textarea {
    width: calc(75% - 20px);
    height: 200px;
    border: 1px solid #636363;
    border-radius: 6px;
    margin: 0px 5px;
  }
  input {
    width: 100% !important;
    border: 1px solid #636363;
  }

  select {
    width: 100% !important;
    border: 1px solid #0082c1;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
`;
export const Text = styled.span`
  display: flex;
  text-align: left;
  font-size: 12px !important;
  align-items: center;
  color: #636363;
  margin-left: 15px;
`;

export const Select = styled.select`
  display: flex;
  width: 70px;
  height: 30px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #0082c1;
  margin: 0px 15px;
  color: #0082c1;

  option {
    background-color: #fff !important;
    border: 1px solid #0082c1 !important;
    box-shadow: 0px 0px 1px #0082c1 !important;
    color: #0082c1;
  }
`;

export const DropzoneContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 135px;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  border: 1px solid #c4c4c4;

  img {
    width: 245px;
    height: 130px;
    object-fit: cover;
  }
`;
export const ContainerFormControl = styled.div`
  display: flex;
  padding: 20px 40px;
  background-color: #f7f7f7;
`;

export const ContainerButtonComplement = styled.div`
  margin: 3% 0%;
  span {
    color: ${colors.blue} !important;
  }
  button {
    padding: 15px 15px;
    border-radius: 10px;
    border: solid 1px ${colors.blue} !important;
    background-color: #ffffff !important;
    cursor: pointer;
  }
`;

export const ContainerComplement = styled.div`
  display: flex;
  padding: 20px;
  margin: 10px;
  flex-direction: column;
  svg {
    width: 20px;
  }

  span {
    font-size: 17px;
  }

  .MuiIconButton-colorSecondary {
    color: ${colors.blue};
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${colors.blue};
  }
`;

export const ContainerAvailability = styled.div`
  display: flex;
  padding: 20px;
  margin: 10px;

  svg {
    width: 20px;
  }

  span {
    font-size: 17px;
  }
  .MuiIconButton-colorSecondary {
    color: ${colors.blue};
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${colors.blue};
  }
`;

export const TabsContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0px;
`;
