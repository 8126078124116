import { Grid } from '@material-ui/core';
import React from 'react';
import {
  Container,
  BodyContainer,
  SubBodyContainer,
  SubBodyContainer2,
  TitleCard,
  Price,
  Svg,
  Income,
  SubBodyContainerImage,
} from './styles';

interface IPropsDTO {
  symbolType: any;
  price: string;
  income: string;
  cardTitle: string;
  type: string;
}
export const CardOrderDashboard = ({ symbolType, price, income, cardTitle, type }: IPropsDTO) => (
  <Container style={{ width: '100vw' }}>
    {type === 'sell' ? (
      <Container style={{ backgroundColor: '#20935C' }}>
        <BodyContainer>
          <SubBodyContainer>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <TitleCard>{cardTitle}</TitleCard>
                <Price>
                  {symbolType}
                  &nbsp;
                  {price}
                </Price>
              </Grid>
              <Grid item style={{ marginRight: '-120px' }}>
                <Income>{income}</Income>
              </Grid>
            </Grid>
          </SubBodyContainer>
          <SubBodyContainer2 />
        </BodyContainer>
        <SubBodyContainerImage>
          <Svg
            style={{ width: '80px', height: 'auto' }}
            src="https://www.svgrepo.com/show/144413/money-bag.svg"
            width="30px"
            height="auto"
          />
        </SubBodyContainerImage>
      </Container>
    ) : null}
    {type === 'info' ? (
      <Container style={{ backgroundColor: '#488AC7', width: '190px', height: '200px' }}>
        <BodyContainer>
          <SubBodyContainer>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <TitleCard>{cardTitle}</TitleCard>
                <Price>
                  {symbolType}
                  &nbsp;
                  {price}
                </Price>
              </Grid>
              <Grid item style={{ marginRight: '-120px' }}>
                <Income>{income}</Income>
              </Grid>
            </Grid>
          </SubBodyContainer>
          <SubBodyContainer2 />
        </BodyContainer>
        <SubBodyContainerImage>
          <Svg style={{ width: '80px', height: 'auto' }} src="https://www.svgrepo.com/show/6802/list.svg" />
        </SubBodyContainerImage>
      </Container>
    ) : null}
    {type === 'visitors' ? (
      <Container style={{ backgroundColor: 'blueviolet', width: '190px', maxHeight: '200px' }}>
        <BodyContainer>
          <SubBodyContainer>
            <TitleCard>{cardTitle}</TitleCard>
            <Price>
              {symbolType}
              &nbsp;
              {price}
            </Price>
          </SubBodyContainer>
          <SubBodyContainer2>
            <Income>{income}</Income>
          </SubBodyContainer2>
        </BodyContainer>
        <SubBodyContainerImage>
          <Svg
            style={{ width: '80px', height: 'auto' }}
            src="https://www.svgrepo.com/show/25831/new-visitor.svg"
            width="30px"
            height="auto"
          />
        </SubBodyContainerImage>
      </Container>
    ) : null}
    {type === 'newusers' ? (
      <Container style={{ backgroundColor: '#008B8B', width: '190px', height: '150px' }}>
        <BodyContainer>
          <SubBodyContainer>
            <TitleCard>{cardTitle}</TitleCard>
            <Price>
              {symbolType}
              &nbsp;
              {price}
            </Price>
          </SubBodyContainer>
          <SubBodyContainer2>
            <Income>{income}</Income>
          </SubBodyContainer2>
        </BodyContainer>
        <SubBodyContainerImage>
          <Svg style={{ width: '80px', height: 'auto' }} src="https://www.svgrepo.com/show/3726/user.svg" />
        </SubBodyContainerImage>
      </Container>
    ) : null}
  </Container>
);

export default CardOrderDashboard;
