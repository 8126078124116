import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/styles';

export const Container = styled.div`
  overflow: visible;
  display: flex;
  width: 100%;
  padding-top: 20px;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const CategoryBodyGroup = styled.div`
  overflow: visible;
  width: 100%;
  height: 100%;
  margin: 0% 0%;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 7px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
`;

export const CategoryHeaderGroup = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CategoryHeader = styled.div`
  display: flex;
  padding: 15px 15px;
  width: 100%;
  height: 90%;
  justify-content: space-space-between;
  align-items: center;
  flex-direction: row;

  svg {
    color: black;
    height: 20px;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${colors.blue};
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.blue};
  }
`;

export const TitleHeader = styled.span`
  width: 60%;
  color: #636363;
  margin: 2%;
  font-weight: 580;
  font-size: 20px;
  text-align: left;
`;

export const ContainerButton = styled.div`
  width: auto;
  button {
    width: auto;
    height: 40px;
    border-radius: 8px;
    background-color: ${colors.blue};
    transition: 0.8s;
  }
  button:hover {
    outline: transparent;
    background-color: ${colors.hoverBlue};
    cursor: pointer;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 10.2%;
    height: 70px;
    border-radius: 8px;
  }

  span {
    display: flex;
    margin-left: 2%;
  }
`;

export const IconExpandCategory = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  /* margin-left: 3%; */
  button {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border: none;
    border-bottom: none;
    cursor: pointer;
  }

  span {
    margin: 0px 5px;
    font-weight: 600;
    color: #808080;
    width: 100%;
    background-color: transparent;
  }

  svg {
    margin: 0px 5px;
    color: red;
  }
`;

export const Line = styled.hr`
  background-color: #636363;
  width: 97%;
`;

export const TableContainer = styled.div`
  overflow: visible;
  display: flex;

  td {
    font-size: 17px;
  }

  /* svg {
    width: 40px;
  } */

  svg {
    color: black;
    height: 22px;
    margin-right: 5px;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${colors.blue};
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.blue};
  }
`;

export const ReorderIconContainer = styled.div`
  svg:hover {
    cursor: grabbing;
  }
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 60%;
    margin: 0%;
    background: transparent;
    border: solid 1px;
    border-color: #c0c0c0;
    border-radius: 7px;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: flex;
    color: blue;
  }
  span {
    font-size: 18px;
    margin: 0px;
  }
`;

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tr: {
    width: '100%',
  },
});
