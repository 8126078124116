import React from 'react';
import { Container } from './styles';
import UnderConstruction from '../../../components/UnderConstruction';

const PageUnderConstruction = () => (
  <Container>
    <UnderConstruction />
  </Container>
);

export default PageUnderConstruction;
