import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #636363;
  width: calc(100% - 20px);
  height: 100%;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b9d964 !important;
    padding: 10px;
    span {
      text-align: center;
      font-size: 14px;
      margin: 0px !important;
      font-weight: 400 !important;
    }
    &:hover {
      background-color: #c0e855 !important;
    }
  }
`;

export const Title = styled.span`
  width: 100%;
  color: #636363;
  font-weight: 580;
  font-size: 18px !important;
  text-align: left;
`;
