/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Modal, Container, TitleContainer, ButtonConfirmContainer, InputContainer } from './styles';
import IconButton from '../IconButton';
import Input from '../InputBase';
import Button from '../Button';
import api from '../../service/api';

interface IPropsDTO {
  onClose: any;
  id: string;
  storesP: string;
}

const ModalCancel = ({ onClose, id, storesP }: IPropsDTO) => {
  const history = useHistory();
  const [reason, setReason] = useState('');
  const [snakOpen, setSnackOpen] = useState<boolean>();
  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;
  const [token] = useState(localStorage.getItem('token'));
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  function handleClick(route: string) {
    history.push(`/${route}`);
  }

  async function handleSubmit() {
    api
      .put(
        `orders/cancel/${id}`,
        {
          storeId: storesP,
          status: 'CANCELED',
          comments: reason,
        },
        config,
      )
      .then((res) => {
        if (res.data.success === true) {
          setSnackOpen(true);
          onClose();
        }
      });
    handleClick('orders');
  }

  function handleInput(value: any) {
    setReason(value.target.value);
  }

  return (
    <Modal>
      <Container>
        <TitleContainer>
          <span>Deseja Cancelar?</span>
          <IconButton icon={<Close />} onClick={onClose} />
        </TitleContainer>
        <InputContainer>
          <Input
            type="text"
            placeholder="Insira aqui o motivo do cancelamento..."
            onChange={(value: string) => handleInput(value)}
          />
        </InputContainer>
        <ButtonConfirmContainer>
          <Button onClick={() => handleSubmit()} title="Confirmar" />
        </ButtonConfirmContainer>
      </Container>
      {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            Pedido Cancelado com Sucesso.
          </Alert>
        </Snackbar>
      ) : null}
    </Modal>
  );
};

export default ModalCancel;
