import React from 'react';
import { Container, Main } from './styles';
import Aside from '../../../components/Aside';
import ProductForm from '../../../components/ProductForm';
import NavBar from '../../../components/NavBar';

const Products = () => (
  <Container>
    <Main>
      <NavBar />
      <ProductForm />
    </Main>
    <Aside />
  </Container>
);

export default Products;
