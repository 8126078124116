import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 15px;
  margin-top: 20px;
  width: 300px !important;
  height: 220px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 20px 10px -8px 10px;
  justify-content: center;
  align-items: center;
`;

export const SubBodyContainer = styled.div`
  margin-top: 2.5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  height: 100%;

  span {
    font-size: 12px !important;
    color: #878793;
    padding: 5px;
  }
`;

export const SubBodyContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  width: 100%;
  height: 100%;

  span {
    font-size: 12px !important;
    color: #878793;
    padding: 5px;
  }
`;

export const SubBodyContainerImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: left;
  padding-left: 200px;
`;

export const TitleCard = styled.h6`
  color: white;
  margin: 0;
`;

export const Price = styled.h4`
  color: white;
  margin-top: 2%;
`;
export const Svg = styled.img``;
export const Income = styled.h6`
  color: #ffff;
  display: flex-end;
`;
