/* eslint-disable arrow-body-style */
import React from 'react';
import CardToDo from '../CardToDo';
import { Container, SubContainer } from './styles';

const ModalBodyToDo = () => {
  // const [newToDo, setNewToDo] = useState('');

  return (
    <Container>
      <SubContainer />
      <CardToDo />
    </Container>
  );
};

export default ModalBodyToDo;
