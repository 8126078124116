import React, { useState } from 'react';
import { NoteOutlined, AssessmentOutlined } from '@material-ui/icons';
import CardButton from '../BaseCardButton';
import CardNote from '../CardNote';
import { Container, SubContainer } from './styles';

const ModalBodyNote = () => {
  const [newNote, setNewNote] = useState<boolean>(false);
  return (
    <Container>
      <SubContainer>
        <CardButton icon={<NoteOutlined />} title="Adicionar Nota" onClick={() => setNewNote(true)} />
        <CardButton icon={<AssessmentOutlined />} title="Exportar ao Excel" />
      </SubContainer>
      {newNote}
      <CardNote
        title="Testando notas"
        text="Bloco de notas é uma aplicação simples com um design minimalista, livre de custos ou publicidade, utiliza esta aplicação para não te esqueceres de algo que tens a fazer. Pode ser-te útil para memorizar os teus recados ou fazeres uma lista de compras"
      />
    </Container>
  );
};

export default ModalBodyNote;
