import React from 'react';
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root'),
);
