import React from 'react';
import { Container, Main } from './styles';
import NavBar from '../../../components/NavBar';
import Orders from '../../../components/Orders';

const Order = () => (
  <Container>
    <Main>
      <Orders />
      <NavBar />
    </Main>
  </Container>
);

export default Order;
