/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-expressions */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../service/auth';
import Orders from '../pages/WebBrowser/Orders';
import Home from '../pages/WebBrowser/Home';
import Products from '../pages/WebBrowser/Products';
import Catalog from '../pages/WebBrowser/Catolog';
import Profile from '../pages/WebBrowser/Profile';
import SignIn from '../pages/WebBrowser/SignIn';
import Delivery from '../pages/WebBrowser/DeliveryArea';
import PageUnderConstruction from '../pages/WebBrowser/UnderConstruction';
import CustomerList from '../pages/WebBrowser/Customers';
// @ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Routes: React.FC = () => {
  const [isAuth, setIstAuth] = useState(false);
  useEffect(() => {
    isAuthenticated() ? setIstAuth(!isAuth) : setIstAuth(false);
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={!isAuth ? SignIn : Home} />
        <PrivateRoute path="/dashboard" component={Home} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/add-product" component={Products} />
        <PrivateRoute path="/catalog" component={Catalog} />
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/delivery" component={Delivery} />
        <PrivateRoute path="/construction" component={PageUnderConstruction} />
        <PrivateRoute path="/customers" component={CustomerList} />
        <PrivateRoute path="/payment-methods" component={CustomerList} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
