/* eslint-disable object-curly-newline */
import React from 'react';
import { Container, SubContainer, TitleContainer, Title, TableContainer } from './styles';
import Caption from '../Caption';
import TableOrders from '../TableOrders';

const Orders = () => (
  <Container>
    <SubContainer>
      <TitleContainer>
        <Title>Gerenciador de Pedidos</Title>
        <Caption />
      </TitleContainer>
      <TableContainer>
        <TableOrders />
      </TableContainer>
    </SubContainer>
  </Container>
);

export default Orders;
