/* eslint-disable object-curly-newline */
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Container } from './styles';

interface IPropsDTO {
  handleConfirm?: any;
  handleClose?: any;
  title: string;
  description: string;
}

export const ModalConfirmation = ({ handleConfirm, title, description, handleClose }: IPropsDTO) => (
  <Container>
    <Dialog open aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancelar
        </Button>
        <Button onClick={handleConfirm}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  </Container>
);

// export default ModalConfirmation;
