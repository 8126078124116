/* eslint-disable operator-linebreak */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Table, TableBody, TableCell, TableHead, TableRow, Grid } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReorderIcon from '@material-ui/icons/Reorder';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Loader from 'react-loader-spinner';
import Switch from '@material-ui/core/Switch';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import CatalogButton from '../CatalogButton ';
import api from '../../service/api';
import Button from '../Button';
import InputBase from '../InputBase';
import cash from '../../util/cash';
import { colors } from '../../style/styles';
import CatalogAddProduct from '../CatalogAddProduct';
import { ModalConfirmation } from '../ModalConfirmation';
import CatalogEditGroup from '../CatalogEditGroup';
import {
  Container,
  CategoryBodyGroup,
  CategoryHeaderGroup,
  TitleHeader,
  CategoryHeader,
  IconExpandCategory,
  Line,
  TableContainer,
  ContainerButton,
  useStyles,
  ImageContainer,
  ReorderIconContainer,
  InputContainer,
  SwitchContainer,
} from './styles';
import CatalogEditProduct from '../CatalogEditProduct';

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

interface IPropsDTO {
  name: string;
  id: any;
  statusCategory: string;
  indexCategory: any;
  typecast: string;
  handleCategoryGroup: any;
}

const CatalogGroup = ({ name, id, statusCategory, indexCategory, typecast, handleCategoryGroup }: IPropsDTO) => {
  const [token] = useState(localStorage.getItem('token'));
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState<any>();
  const [productCategory, setProductCategory] = useState<any>();
  const [snakOpen, setSnackOpen] = useState<boolean>();
  const [loader, setLoader] = useState<boolean>();
  const [addingProduct, setAddingProduct] = useState<boolean>(false);

  const options = ['Editar item', 'Excluir item'];
  const ITEM_HEIGHT = 48;
  const [meunOptionsId, setMenuOptionsId] = useState<string>('');
  const [meunOptionsName, setMenuOptionsName] = useState<string>('');
  const [modalDeleteProduct, setModalDeleteProduct] = useState<boolean>(false);
  const [modalEditGroup, setModalEditGroup] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElGroup, setAnchorElGroup] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const openMenuGroup = Boolean(anchorElGroup);
  const [alertMessage, setAlertMessage] = useState('');

  const handleProductGroup = (productId: any) => {
    const idProduct = { id: productId };
    setProduct(idProduct);
    setOpen(!open);
  };

  const handleProductsData = async () => {
    await api.get(`products/product/${product?.id}`, config).then((response) => {
      setProductCategory(response.data);
    });
  };

  useEffect(() => {
    handleProductsData();
  }, [product?.id]);

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination || result.destination.index === result.source.index) return;
    setLoader(true);
    const items = Array.from(productCategory);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    await api.put(`products/ordernation/${product?.id}`, items, config).then(() => {
      setTimeout(() => {
        setLoader(false);
        setProductCategory(items);
        setAlertMessage('Itens ordenados com sucesso');
        setSnackOpen(true);
      }, 2000);
    });
  };

  function handleImageProduct(nameProduct: string, imageProduct: string) {
    // eslint-disable-next-line prettier/prettier
    const image =
      imageProduct.indexOf('undefined') !== -1
        ? 'https://portal.ifood.com.br/partner-portal-catalog-web-front/static/media/empty-image.b6a6fda6.svg'
        : imageProduct;
    return (
      <ImageContainer>
        <img src={image} alt="" />
        <span>{nameProduct}</span>
      </ImageContainer>
    );
  }

  function handlePriceProduct(priceProduct: string) {
    return (
      <InputContainer>
        <InputBase value={cash(priceProduct)} disabled type="" />
      </InputContainer>
    );
  }

  const handleChangeStatusCategory = async (statusProduct: any, productId: string) => {
    let status;
    statusProduct === 'A' ? (status = 'D') : (status = 'A');
    const productStatus = { id: productId, action: status };
    await api.post('products/item/status/', productStatus, config).then(() => {
      setLoader(false);
      handleCategoryGroup();
    });
  };

  const handleChangeStatusProduct = async (statusProduct: any, productId: any, index: any, typeCast: any) => {
    setLoader(true);
    if (typeCast === 'G') {
      handleChangeStatusCategory(statusProduct, productId);
      return;
    }
    const status = statusProduct;
    const items = Array.from(productCategory);
    const [itemStatus] = items.splice(index, 1);
    // @ts-ignore
    status === 'D' ? (itemStatus.status = 'A') : (itemStatus.status = 'D');
    items.splice(index, 0, itemStatus);
    setProductCategory(items);
    // @ts-ignore
    const productStatus = { id: productId, action: itemStatus.status };
    await api.post('products/item/status/', productStatus, config).then(() => {
      setLoader(false);
    });
  };

  function handleStatusProduct(statusProduct: any, productId: string, index: string, typeCast: string) {
    return (
      <SwitchContainer>
        <Grid component="label" container alignItems="center" spacing={0}>
          <Grid item>
            <span>Pausar</span>
          </Grid>
          <Grid item>
            {statusProduct === 'A' ? (
              <Switch
                checked
                onChange={() => handleChangeStatusProduct(statusProduct, productId, index, typeCast)}
                name="ativo"
              />
            ) : (
              <Switch
                checked={false}
                onChange={() => handleChangeStatusProduct(statusProduct, productId, index, typeCast)}
                name="desativado"
              />
            )}
          </Grid>
          <Grid item>
            <span>Ativado</span>
          </Grid>
        </Grid>
      </SwitchContainer>
    );
  }

  const DraggableTableCell = (props: any) => {
    const ref = useRef();
    // @ts-ignore
    const { width = null, height = null } = ref.current ? window.getComputedStyle(ref.current) : {};

    useLayoutEffect(() => {
      // @ts-ignore
      ref.current.style.width = width;
      // @ts-ignore
      ref.current.style.height = height;
    }, [width, height, ref]);

    return <TableCell ref={ref} {...props} />;
  };

  const handleAddingProduct = () => {
    setAddingProduct(true);
  };

  const handleClickOptionsProduct = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsId(event.currentTarget.id);
    // @ts-ignore
    setMenuOptionsName(event.currentTarget.name);
    setAnchorEl(event.currentTarget);
  };

  const handleSelectMenuOptionsProduct = (event: React.MouseEvent<HTMLElement>) => {
    if (!event.currentTarget.innerText) {
      setAnchorEl(null);
      return;
    }
    if (event.currentTarget.innerText === 'Excluir item') {
      console.log(event.currentTarget.innerText);
      setModalDeleteProduct(true);
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
      setOpenModalEdit(true);
    }
  };
  const handleMenuOptions = (productId: string, productName: string) => (
    <div>
      <IconButton
        aria-expanded={openMenu ? 'true' : undefined}
        id={productId}
        name={productName}
        onClick={handleClickOptionsProduct}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        disableScrollLock={false}
        transitionDuration="auto"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        open={openMenu}
        onClose={handleSelectMenuOptionsProduct}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '15ch',
            border: '2px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0 0 2px rgba(0, 0, 0, 0.1)',
          },
        }}>
        {options.map((option) => (
          <MenuItem key={option} id={productId} onClick={handleSelectMenuOptionsProduct}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  const handleClickOptionsGroup = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsId(event.currentTarget.id);
    // @ts-ignore
    setMenuOptionsName(event.currentTarget.name);
    setAnchorElGroup(event.currentTarget);
  };

  const handleSelectMenuOptionsGroup = (event: React.MouseEvent<HTMLElement>) => {
    if (!event.currentTarget.innerText) {
      setAnchorElGroup(null);
      return;
    }
    if (event.currentTarget.innerText === 'Excluir item') {
      setModalDeleteProduct(true);
      setAnchorElGroup(null);
    } else {
      setAnchorElGroup(null);
      setModalEditGroup(true);
    }
  };
  const handleMenuOptionsGroup = (groupId: string, groupName: string) => (
    <div>
      <IconButton
        aria-expanded={openMenuGroup ? 'true' : undefined}
        id={groupId}
        name={groupName}
        onClick={handleClickOptionsGroup}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElGroup}
        disableScrollLock={false}
        transitionDuration="auto"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        open={openMenuGroup}
        onClose={handleSelectMenuOptionsGroup}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '15ch',
            border: '2px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0 0 2px rgba(0, 0, 0, 0.1)',
          },
        }}>
        {options.map((option) => (
          <MenuItem key={option} id={groupId} onClick={handleSelectMenuOptionsGroup}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  const handleDeleteProduct = async () => {
    const status = { status: 'I' };
    await api.put(`/products/delete/${meunOptionsId}`, status, config).then((res) => {
      if (res.data.success === true) {
        setModalDeleteProduct(false);
        setAlertMessage('Item excluído com sucesso');
        setSnackOpen(!snakOpen);
        handleProductsData();
        setTimeout(() => {
          handleCategoryGroup();
        }, 3000);
      }
    });
  };
  return (
    <>
      {addingProduct ? (
        <div style={{ position: 'absolute', width: '80%', height: '100vh', zIndex: 2 }}>
          <CatalogAddProduct
            onClose={() => {
              setAddingProduct(false);
            }}
            handleCategoryProduct={() => handleProductsData()}
          />
        </div>
      ) : null}
      {openModalEdit ? (
        <CatalogEditProduct
          id={meunOptionsId}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          handleCategoryProduct={() => handleProductsData()}
        />
      ) : null}
      {modalDeleteProduct ? (
        <>
          <ModalConfirmation
            title="Atenção"
            handleConfirm={() => handleDeleteProduct()}
            handleClose={() => setModalDeleteProduct(false)}
            description={`Isto irá excluir o item: ${meunOptionsName}. Deseja confirmar?`}
          />
        </>
      ) : null}
      {modalEditGroup ? (
        <>
          <CatalogEditGroup id={meunOptionsId} onClose={() => setModalEditGroup(false)} />
        </>
      ) : null}
      <Container>
        <CategoryBodyGroup>
          <CategoryHeaderGroup>
            <CategoryHeader>
              <TitleHeader>{name}</TitleHeader>
              <ContainerButton>
                <Button onClick={handleAddingProduct} title="+ Adicionar item" />
              </ContainerButton>
              <IconExpandCategory>
                <CatalogButton
                  id={id}
                  expand
                  iconExpand={open ? <ExpandLess /> : <ExpandMore />}
                  onClick={() => handleProductGroup(id)}
                />
              </IconExpandCategory>
              {handleStatusProduct(statusCategory, id, indexCategory, typecast)}
              <div>{handleMenuOptionsGroup(id, name)}</div>
            </CategoryHeader>
            <Line />
          </CategoryHeaderGroup>
          {open ? (
            <TableContainer>
              {loader ? (
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Loader type="ThreeDots" color={`${colors.blue}`} height={400} width={400} />
                </Grid>
              ) : (
                <Table className={classes.table}>
                  <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '57%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '18%' }} />
                    <col style={{ width: '0%' }} />
                  </colgroup>
                  {open ? (
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{ width: '0px' }}>
                          &nbsp;
                        </TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>Preço</TableCell>
                        <TableCell>Status de venda</TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                  ) : null}
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="category" direction="vertical">
                      {(provided: any) => (
                        <TableBody className="category" {...provided.droppableProps} ref={provided.innerRef}>
                          {productCategory.map((products: any, index: any) => (
                            <Draggable key={products.id} draggableId={products.id} index={index}>
                              {(provideded) => (
                                <TableRow ref={provideded.innerRef} {...provideded.draggableProps} key={products.id}>
                                  <DraggableTableCell>
                                    <ReorderIconContainer
                                      style={{ marginLeft: '15px' }}
                                      {...provideded.dragHandleProps}>
                                      <ReorderIcon />
                                    </ReorderIconContainer>
                                  </DraggableTableCell>
                                  <DraggableTableCell>
                                    {handleImageProduct(products.name, products.image)}
                                  </DraggableTableCell>
                                  <DraggableTableCell>{handlePriceProduct(products.price)}</DraggableTableCell>
                                  <TableCell>
                                    {handleStatusProduct(products.status, products.id, index, products.typecast)}
                                  </TableCell>
                                  <TableCell>{handleMenuOptions(products.id, products.name)}</TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              )}
            </TableContainer>
          ) : null}
        </CategoryBodyGroup>
      </Container>
      {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={3000} onClose={() => setSnackOpen(false)}>
          <Alert severity="success">{alertMessage}</Alert>
        </Snackbar>
      ) : null}
    </>
  );
};
export default CatalogGroup;
