/* eslint-disable max-len */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Grid, Typography } from '@material-ui/core';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { BiCycling, BiFoodMenu, BiStore, BiLogOut, BiCreditCard, BiDoorOpen } from 'react-icons/bi';
import Link from '@material-ui/core/Link';
import { DrawerContainer, Container } from './styles';
import { IStoreDTO } from '../../interfaces/IStoreDTO';
import { logout } from '../../service/auth';

const DrawerComponent = () => {
  const history = useHistory();
  const [store] = useState<IStoreDTO>();
  function handleClick(route: string) {
    history.push(`/${route}`);
  }

  function handleClickLogout() {
    logout();
    window.location.reload();
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.meupedido.online/">
          Meu Pedido Online
        </Link>{' '}
        {new Date().getFullYear()}.
      </Typography>
    );
  }

  return (
    <DrawerContainer>
      <Container>
        <ProSidebar>
          <SidebarHeader>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid style={{ marginTop: '4vh', marginBottom: '4vh' }}>
                <img
                  alt="Logo Meu Pedido Online"
                  src="https://www.meupedido.online/static/media/logo_dark.4bc2fe68.png"
                  width="180px"
                  height="auto"
                />
              </Grid>
              <Grid>
                <Typography style={{ fontSize: '30px', color: '#283941' }}>{store?.name}</Typography>
              </Grid>
            </Grid>
          </SidebarHeader>
          <SidebarContent>
            <Menu subMenuBullets>
              <MenuItem
                onClick={() => {
                  handleClick('dashboard');
                }}
                icon={<AiOutlineDashboard color="black" />}>
                Dashboard
              </MenuItem>
              <SubMenu title="Menu" icon={<BiFoodMenu color="black" />}>
                <MenuItem
                  onClick={() => {
                    handleClick('catalog');
                  }}>
                  Catalogo
                </MenuItem>
              </SubMenu>
              <MenuItem
                onClick={() => {
                  handleClick('orders');
                }}
                icon={<FaClipboardList color="black" />}>
                Pedidos
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClick('delivery');
                }}
                icon={<BiCycling color="black" />}>
                Área de Entrega
              </MenuItem>
              {/* <MenuItem
              onClick={() => {
                handleClick('payment-methods');
              }}
              icon={<BiCreditCard color="black" />}>
              Formas de pagamento
            </MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleClick('profile');
                }}
                icon={<BiStore color="black" />}>
                Perfil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClickLogout();
                }}
                icon={<BiLogOut color="black" />}>
                Sair
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter>{Copyright()}</SidebarFooter>
        </ProSidebar>
      </Container>
    </DrawerContainer>
  );
};
export default DrawerComponent;
