import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
`;

export const CardsContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 30px);
  height: auto;
  padding: 10px;
  margin: 5px 5px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c4c4c4;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 14px);
  padding: 7px;

  textarea {
    width: calc(75% - 20px);
    height: 200px;
    border: 1px solid #636363;
    border-radius: 6px;
    margin: 0px 5px;
  }
  span {
    display: contents;
  }

  input {
    width: 100% !important;
    border: 1px solid #636363;
  }

  select {
    width: 100% !important;
    border: 1px solid #0082c1;
  }

  button {
    background: none;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
`;
export const Text = styled.span`
  display: flex;
  text-align: left;
  font-size: 12px !important;
  align-items: center;
  color: #636363;
  margin-left: 15px;
`;

export const Select = styled.select`
  display: flex;
  width: 70px;
  height: 30px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #0082c1;
  margin: 0px 15px;
  color: #0082c1;

  option {
    background-color: #fff !important;
    border: 1px solid #0082c1 !important;
    box-shadow: 0px 0px 1px #0082c1 !important;
    color: #0082c1;
  }
`;

export const ContainerButtonIncrement = styled.div`
  display: flex;
  position: absolute;
  margin: 0% 3%;
  margin-bottom: 10px;
  span {
    color: red;
  }
  button {
    color: rgb(234, 29, 44);
    padding: 15px 15px;
    border-radius: 10px;
    border: none;
    background-color: rgb(252, 235, 234) !important;
  }
`;

export const ContainerGroup = styled.div`
  display: flex;
  width: 95%;
`;
