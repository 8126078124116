/* eslint-disable no-param-reassign */
const mMoeda = (v: any) => {
  v = v.toString();
  v = v.replace(/\D/g, ''); // permite digitar apenas numeros
  v = v.replace(/(\d{1})(\d{8})$/, '$1.$2'); // coloca ponto antes dos ultimos 8 digitos
  v = v.replace(/(\d{1})(\d{5})$/, '$1.$2'); // coloca ponto antes dos ultimos 5 digitos
  v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2'); // coloca virgula antes dos ultimos 2 digitos
  return v;
};

export default mMoeda;
