import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100% - 40px);
  height: auto;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SubContainer1 = styled.div`
  display: flex;
  width: calc(55% - 50px);
  height: auto;
  padding: 0px 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 0 1px #636363;
  margin: 5px;
  margin-right: 20px;
`;

export const SubContainer2 = styled.div`
  display: flex;
  width: calc(45% - 50px);
  height: calc(100% - 50px);
  padding: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 0 1px #636363;
  margin: 5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const Title = styled.span`
  color: #636363;
  width: 100%;
  font-weight: 580;
  font-size: 24px;
  text-align: left;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(40% - 20px);
  height: auto;
  align-items: center;
  justify-content: flex-end;
  padding: 10px !important;
  border-radius: 20px;
  border: 1px solid #c4c4c4;

  img {
    margin: 10px 0px;
    width: 140px;
    height: auto;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px;
  margin: 15px 5px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c4c4c4;
`;

export const TitleCard = styled.span`
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  text-align: left;
  align-items: center;
  color: #636363;
`;

export const Text = styled.span`
  display: flex;
  text-align: left;
  font-size: 12px !important;
  align-items: center;
  color: #636363;
  margin-left: 15px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 14px);
  padding: 7px;

  textarea {
    width: calc(75% - 20px);
    height: 200px;
    border: 1px solid #636363;
    border-radius: 6px;
    margin: 0px 5px;
  }
  input {
    width: 100% !important;
    border: 1px solid #636363;
  }

  select {
    width: 100% !important;
    border: 1px solid #0082c1;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
`;

export const Select = styled.select`
  display: flex;
  width: 70px;
  height: 30px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #0082c1;
  margin: 0px 15px;
  color: #0082c1;

  option {
    background-color: #fff !important;
    border: 1px solid #0082c1 !important;
    box-shadow: 0px 0px 1px #0082c1 !important;
    color: #0082c1;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  span {
    text-align: center;
    color: #fff !important;
    font-size: 18px !important;
    margin: 0px !important;
    font-weight: 400 !important;
  }
`;

export const ButtonSaveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
  button {
    background-color: #b9d964 !important;
    padding: 20px 20px;
    &:hover {
      background-color: #c0e855 !important;
    }
  }
`;
