import React from 'react';
import { Archive } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { Container, SubContainer, FooterContainer } from './styles';
import CardButton from '../BaseCardButton';

interface IPropsDTO {
  onClickArchive?: any;
}

const CardToDo = ({ onClickArchive }: IPropsDTO) => (
  <Container>
    <SubContainer>
      <Typography>O Estabelecimento se econtra: fechado</Typography>
    </SubContainer>
    <FooterContainer>
      <CardButton onClick={onClickArchive} icon={<Archive />} title="Abrir" />
    </FooterContainer>
  </Container>
);

export default CardToDo;
