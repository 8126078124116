/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Typography, Snackbar } from '@material-ui/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { v4 as uuidV4 } from 'uuid';
import { IProductByTypecastDTO } from '../../interfaces/IProductDTO';
import api from '../../service/api';
import Dropzone from '../Dropzone';
import Input from '../InputBase';
import {
  Container,
  ContainerCategory,
  SubContainer,
  TitleContainer,
  Title,
  ContainerTabPanelDetails,
  CardsContainer,
  DropzoneContainer,
  DropzoneSubContainer,
  ImageContainer,
  Card,
  InputContainer,
  ButtonsContainer,
  ButtonsContainerCancel,
  ButtonsContainerContinue,
  CategoriesContainer,
  CategoriesBaseContainer,
  CategoriesBaseHeader,
  TabsContainer,
} from './styles';
import Button from '../Button';
import CatalogGroup from '../CatalogGroup';

const Catalog = () => {
  const [token] = useState(localStorage.getItem('token'));
  const [groupCategory, setGroupCategory] = useState<IProductByTypecastDTO[]>();
  const [modalCategory, setModalCategory] = useState<boolean>(false);
  const [selectedFileProduct, setSelectedFileProduct] = useState<File>();
  const [selectedFileFeatured, setSelectedFileFeatured] = useState<File>();
  const [name, setName] = useState<string>('');
  const [title, setTitle] = useState<string>('Nova categoria');
  const [buttonSaveDisabled, setButtonSaveDisabled] = useState<boolean>(true);

  const [snakOpen, setSnackOpen] = useState<boolean>();
  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const handleGetGroup = () => {
    api.get('products/group/', config).then((response) => {
      setGroupCategory(response.data);
    });
  };

  useEffect(() => {
    handleGetGroup();
  }, []);

  const imgProduct = selectedFileProduct ? (
    <>
      <div key={selectedFileProduct.name}>
        <img src={URL.createObjectURL(selectedFileProduct)} />
      </div>
    </>
  ) : null;

  const imgFeatured = selectedFileFeatured ? (
    <>
      <div key={selectedFileFeatured.name}>
        <img src={URL.createObjectURL(selectedFileFeatured)} />
      </div>
    </>
  ) : null;

  useEffect(() => {
    if (name === '') {
      setTitle('Nova categoria');
      setButtonSaveDisabled(true);
      return;
    }
    setButtonSaveDisabled(false);
    setTitle(name);
  }, [name, modalCategory]);

  function handleInput(set: any, value: any) {
    set(value.target.value);
  }

  async function handleSubmit() {
    const productId = uuidV4();
    // @ts-ignore
    const store = JSON.parse(localStorage.getItem('authStore'));
    const categoryDataForm = new FormData();
    categoryDataForm.append('id', productId);
    categoryDataForm.append('typecast', 'G');
    categoryDataForm.append('name', `${name}`);
    categoryDataForm.append('productId', 'null');
    categoryDataForm.append('description', 'null');
    categoryDataForm.append('price', 'null');
    categoryDataForm.append('maximum', '0');
    categoryDataForm.append('minimum', '0');
    categoryDataForm.append('calculation', 'N');
    categoryDataForm.append('status', 'A');
    categoryDataForm.append('storeId', `${store ? store.id : ''}`);
    categoryDataForm.append('primaryLevel', 'Y');
    categoryDataForm.append('complementPagination', 'S');
    categoryDataForm.append('ordernation', '0');
    selectedFileProduct ? categoryDataForm.append('image', selectedFileProduct) : null;
    categoryDataForm.append('prodMode', 'N');

    await api
      .post('products/category/', categoryDataForm, config)
      .then(async (res) => {
        if (res.data.success === true && selectedFileFeatured) {
          const categoryData = new FormData();
          categoryData.append('id', res.data.id);
          selectedFileFeatured ? categoryData.append('image', selectedFileFeatured) : null;
          await api.put('products/category/img-featured/', categoryData, config);
        }
      })
      .finally(() => {
        setSnackOpen(!snakOpen);
        setTimeout(() => {
          setModalCategory(false);
          handleGetGroup();
        }, 3000);
      });
  }

  const handleCancelCategory = () => {
    setName('');
    setSelectedFileProduct(undefined);
    setSelectedFileFeatured(undefined);
    setModalCategory(false);
  };

  return (
    <Container>
      {modalCategory ? (
        <>
          <ContainerCategory>
            <SubContainer>
              <TitleContainer>
                <Title>{title}</Title>
              </TitleContainer>
              <TabsContainer>
                <Tabs>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TabList>
                      <Tab>Detalhes da categoria</Tab>
                    </TabList>
                  </div>
                  <TabPanel>
                    <ContainerTabPanelDetails>
                      <CardsContainer>
                        <Card>
                          <Typography variant="subtitle2">Nome do produto:</Typography>
                          <InputContainer>
                            <InputContainer>
                              <Input
                                type="text"
                                placeholder=""
                                value={name}
                                onChange={(value: string) => handleInput(setName, value)}
                              />
                            </InputContainer>
                          </InputContainer>
                          <DropzoneContainer>
                            <Typography variant="subtitle2">Imagem do produto:</Typography>
                            <DropzoneSubContainer>
                              <Dropzone onFileUploaded={setSelectedFileProduct} />
                              <ImageContainer>{imgProduct}</ImageContainer>
                            </DropzoneSubContainer>
                          </DropzoneContainer>
                          <DropzoneContainer>
                            <Typography variant="subtitle2">Imagem de destaque:</Typography>
                            <DropzoneSubContainer>
                              <Dropzone onFileUploaded={setSelectedFileFeatured} />
                              <ImageContainer>{imgFeatured}</ImageContainer>
                            </DropzoneSubContainer>
                          </DropzoneContainer>
                        </Card>
                      </CardsContainer>
                    </ContainerTabPanelDetails>
                  </TabPanel>
                </Tabs>
              </TabsContainer>
              <ButtonsContainer>
                <ButtonsContainerCancel>
                  <Button onClick={() => handleCancelCategory()} title="Cancelar" />
                </ButtonsContainerCancel>
                <ButtonsContainerContinue>
                  <Button disabled={buttonSaveDisabled} onClick={handleSubmit} title="Salvar" />
                </ButtonsContainerContinue>
              </ButtonsContainer>
            </SubContainer>
          </ContainerCategory>
        </>
      ) : null}
      <CategoriesContainer>
        <CategoriesBaseContainer>
          <CategoriesBaseHeader>
            <Button onClick={() => setModalCategory(true)} title="+ Adicionar categoria" />
          </CategoriesBaseHeader>
          {groupCategory // @ts-ignore
            ? groupCategory.map((group: any, index: any) => <CatalogGroup indexCategory={index} key={group.id} id={group.id} name={group.name} statusCategory={group.status} typecast={group.typecast} handleCategoryGroup={() => handleGetGroup()} />)
            : null}
        </CategoriesBaseContainer>
      </CategoriesContainer>
      {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            Produto Adicionado com Sucesso !
          </Alert>
        </Snackbar>
      ) : null}
    </Container>
  );
};
export default Catalog;
