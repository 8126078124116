import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  width: calc(60px - 20px);
  height: calc(100% - 20px);
  padding: 10px;
  border-left: 1px solid #c4c4c4;
  box-shadow: 0 0 2px #c4c4c4;
  flex-direction: column;
  background-color: #fff;
  right: 0px;
  top: 0px;
  position: fixed;
  align-items: center;

  button {
    margin-bottom: 25px !important;
  }

  svg {
    margin: 0px 25px;
    color: #181818 !important;
  }
  svg:hover {
    color: #0000ff !important;
  }
`;

export const SubContainer = styled.footer`
  display: flex;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-bottom: 5px !important;
  }
`;
