/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Container } from './styles';
import api from '../../service/api';
import { ICustomerDTO } from '../../interfaces/ICustomerDTO';
import phone from '../../util/phone';

const TableCustomerList = () => {
  const [customer, setCustomer] = useState<ICustomerDTO[]>();
  const [token] = useState(localStorage.getItem('token'));

  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  useEffect(() => {
    api.get('customers/', config).then((response) => {
      setCustomer(response.data);
    });
  }, []);

  return (
    <Container>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Telefone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customer
            ? customer.map((customers: any) => (
                <TableRow key={customers.id}>
                  <TableCell scope="row">{customers.name}</TableCell>
                  <TableCell>{phone(customers.phone)}</TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Container>
  );
};
export default TableCustomerList;
