/* eslint-disable object-curly-newline */
import React from 'react';
import { Container, SubContainer, TitleContainer, Title, TableContainer } from './styles';
import TableCustomerList from '../TableCustomerList';

const CustomerList = () => (
  <Container>
    <SubContainer>
      <TitleContainer>
        <Title>Lista de Clientes</Title>
      </TitleContainer>
      <TableContainer>
        <TableCustomerList />
      </TableContainer>
    </SubContainer>
  </Container>
);

export default CustomerList;
