import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 200px;

  svg {
    height: calc(100px - 10px);
    width: auto;
    margin: 10px 0px;
    padding: 10px;
  }

  span {
    font-size: 18px !important;
    padding: 10px;
    margin: 10px 0px;
  }

  input {
    border: 1.5px solid #dcdcde;
    margin: 10px 0px;
  }

  button {
    margin: 10px 0px;
    width: calc(290px - 10px);
  }
`;
