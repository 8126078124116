import React, { useState, useEffect } from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryStack } from 'victory';
import { Grid, Typography } from '@material-ui/core';
import api from '../../service/api';
import { IStoreParamsDTO } from '../../interfaces/IStoreDTO';
import { Container, Content } from './styles';

const Card = () => {
  const [storeArray] = useState(localStorage.getItem('authStore'));
  const [monthData, setMonthData] = useState<any>();
  const [month, setMonth] = useState<any>();
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  let setStores: IStoreParamsDTO;
  if (storeArray !== null) setStores = JSON.parse(storeArray);

  // @ts-ignore
  const storeId = { storeId: setStores?.store_name };
  useEffect(() => {
    api.post('orders/monthinfo', storeId, config).then((response) => {
      setMonthData(response.data);
      const arr: any = [];
      response.data.forEach((months: any) => {
        arr.push(months.date.slice(0, 5));
      });
      setMonth(arr);
    });
  }, []);

  return (
    <>
      <Container>
        {monthData ? (
          <VictoryChart
            width={900}
            height={260}
            domainPadding={{ x: 15 }}
            padding={{
              top: 20,
              bottom: 20,
              left: 45,
              right: 10,
            }}>
            <VictoryAxis
              style={{
                tickLabels: {
                  fontSize: 8,
                },
              }}
              tickValues={[]}
              tickFormat={month}
            />
            <VictoryAxis
              style={{
                tickLabels: {
                  fontSize: 8,
                },
              }}
              dependentAxis
              tickFormat={(y) => `R$ ${y}`}
            />
            <VictoryStack colorScale="blue">
              <VictoryBar style={{ data: { width: 5 } }} data={monthData} x="date" y="sales" />
            </VictoryStack>
          </VictoryChart>
        ) : (
          <Content>
            <Grid justifyContent="center" alignItems="center">
              <Typography>Não foi encontrado dados para os ultimos 30 dias...</Typography>
            </Grid>
          </Content>
        )}
      </Container>
    </>
  );
};
export default Card;
