import styled from 'styled-components';

export const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999999;
  background-color: rgb(99, 99, 99, 0.6);
  width: calc(100% + 60px);
  height: calc(100% + 75px);
  top: 0px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(698px - 60px);
  background-color: #fff;
  border-radius: 60px;
  height: calc(420px - 60px);
  box-shadow: 2px #181818;
  border: 2px solid #636363;
  padding: 30px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #181818;
  width: calc(100% - 30px);
  padding: 15px;

  span {
    font-weight: 600;
    color: #636363;
  }

  svg {
    color: #636363 !important;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  padding: 15px;

  input {
    width: 100% !important;
    size: 100% !important;
    box-shadow: 0 0 5px #181818 !important;
    border: 2px solid #636363;
  }
`;

export const ButtonConfirmContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 30px);
  padding: 15px;

  button {
    background-color: #b9d964 !important;
    padding: 20px 45px;

    &:hover {
      background-color: #c0e855 !important;
    }
  }
`;
