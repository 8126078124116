import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 9999999;
  right: 60px;
  top: 0px;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #dcdcd0;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: calc(400px - 10px);
  height: calc(100% - 10px);
  background-color: #fff;
  position: relative;
  top: 0px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dcdcde;
  width: calc(100% - 20px);
  height: calc(75px - 20px);
  padding: 10px;
  position: absolute;
  top: 5px;

  span {
    font-weight: 500;
    color: #3e3e3e;
    position: relative;
  }

  svg {
    color: #3e3e3e !important;
    position: relative;
  }
`;
