import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 5px;
  position: relative;
  top: 85px;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    border: none !important;
    height: 0% !important;
    margin: 10px 0px;

    svg {
      height: 20px;
      width: auto;
      color: #b9d964 !important;
    }

    span {
      color: #b9d964 !important;
    }

    &:hover {
      background-color: #fff !important;

      svg {
        color: #a6ce39 !important;
      }

      span {
        color: #a6ce39 !important;
      }
    }
  }
`;
