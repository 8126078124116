import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 353px;
  height: 155px;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 2px 10px #c4c4c4;
  position: relative;
  margin-bottom: 15px;
  margin-top: 15px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ReceivedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  padding: 2.5px;

  svg {
    color: #ff706b;
  }
`;

export const ApprovedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  padding: 2.5px;

  svg {
    color: #ffaa00;
  }
`;

export const ReadyTrafficContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  padding: 2.5px;

  svg {
    color: #7b68ee;
  }
`;

export const TrafficContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  padding: 2.5px;

  svg {
    color: #b9d964;
  }
`;

export const ConcludedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  padding: 2.5px;

  svg {
    color: #0082c1;
  }
`;

export const CanceledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  padding: 2.5px;

  svg {
    color: #181818;
  }
`;

export const Text = styled.span`
  display: flex;
  text-align: left;
  font-size: 12px !important;
  align-items: center;
  color: #636363;
  margin-left: 15px;
`;
