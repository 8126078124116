const phone = (oEvent: any) => {
  const value = oEvent;
  let valuePhone = value.replace(/\D/g, '');
  valuePhone = valuePhone.replace(/^0/, '');
  if (valuePhone.length > 10) {
    valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (valuePhone.length === 7) {
    valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2$3');
  } else if (valuePhone.length > 7) {
    valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
  } else if (valuePhone.length > 2) {
    valuePhone = valuePhone.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else if (valuePhone.length === 0) {
    valuePhone = valuePhone.replace(/^(\d*)/, '$1');
  } else {
    valuePhone = valuePhone.replace(/^(\d*)/, '($1');
  }

  return valuePhone;
};

export default phone;
