/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Snackbar, Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { v4 as uuidV4 } from 'uuid';
import Button from '../Button';
import Input from '../InputBase';
import CardComplement from '../CardComplement';
import { IComplementDTO } from '../../interfaces/IProductDTO';
import {
  Container,
  SubContainer,
  TitleContainer,
  Title,
  ButtonsContainer,
  ButtonsContainerCancel,
  ButtonsContainerContinue,
  Card,
  InputContainer,
  Text,
  ContainerComplement,
  SubContainerComplement,
  ContainerButtonIncrement,
  TabsContainer,
  SwitchContainer,
} from './styles';

interface IRouteParams {
  onClose: any;
  complementId: any;
}

const CatalogAddComplement = ({ onClose, complementId }: IRouteParams) => {
  const dispatch: any = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [title, setTitle] = useState<string>('Novo grupo de itens');
  const [snakOpen, setSnackOpen] = useState<boolean>();
  const [complementEdit, setComplementEdit] = useState<any>([]);
  const [copyComplementAndIncrement, setCopyComplementAndIncrement] = useState<any>([]);
  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

  const complementName: string = useSelector((state: any) => state.complementName);
  const incrementsData: any = useSelector((state: any) => state.incrementData);
  const complementData: any = useSelector((state: any) => state.complementData);
  const externalCodeComplement: any = useSelector((state: any) => state.externalCodeComplement);
  const [isProduct, setIsProduct] = useState<boolean>();

  const minimum: any = useSelector((state: any) => state.minimum);
  const maximum: any = useSelector((state: any) => state.maximum);

  useEffect(() => {
    // const increment = allIncrements.increments;
    if (complementId === 'new') {
      setIsProduct(false);
      dispatch({ type: 'EXTERNAL_CODE_COMPLEMENT', externalCodeComplement: null });
      return;
    }
    setIncrement(complementId.increments);
    dispatch({ type: 'ADD_INCREMENT', increment });
    dispatch({ type: 'EXTERNAL_CODE_COMPLEMENT', externalCodeComplement: complementId.external_code });
    // delete allIncrements.increments;
    dispatch({ type: 'COMPLEMENT_NAME', complementName: complementId.name });
    setComplementEdit(complementId);
    setCopyComplementAndIncrement(JSON.parse(JSON.stringify(complementId)));
  }, []);

  useEffect(() => {
    if (!complementName) {
      setTitle('Novo grupo de itens');
      return;
    }
    setTitle(complementName);
  }, [complementName]);

    const [increment, setIncrement] = useState<IComplementDTO[]>([]);

  useEffect(() => {
    dispatch({ type: 'ADD_INCREMENT', increment });
  }, [increment]);

  const handleAddingComplement = () => {
    increment
      ? setIncrement([
          ...increment,
          {
            id: uuidV4(),
            name: '',
            minimum: '',
            maximum: '',
            ordernation: increment.length + 1,
            price: '',
            status: 'A',
            index: increment.length,
            isNew: 'newIncrement',
            externalCode: '',
          },
        ])
      : setIncrement([
          {
            id: uuidV4(),
            name: '',
            minimum: '',
            maximum: '',
            ordernation: 1,
            price: '',
            index: 0,
            status: 'A',
            isNew: 'newIncrement',
            externalCode: '',
          },
        ]);
  };

  const handleDeleteIncrement = (indexIncrement: any) => {
    const removeIndex = indexIncrement;
    increment.splice(removeIndex, 1);
    increment.forEach((increments, index) => {
      increments.ordernation = index + 1;
    });
    setIncrement([...increment]);
  };

  const handleSaveIncrement = () => {
    let complementSave;
  if (complementEdit.id) {
    const index = complementData.findIndex((complementItem: any) => complementItem.id === complementEdit.id);

    complementData.splice(index, 1);
    const complement = complementData;
    dispatch({ type: 'REMOVE_COMPLEMENT', complement });
    const increments: any = [...increment];
    if (increments.length > 0) {
      increments.forEach((item: any) => {
        // @ts-ignore
        item.id = item.id ? item.id : uuidV4();
        // @ts-ignore
        item.product_id = complementEdit.id;
        item.parentName = complementName;
        complementSave = [{ name: complementName, id: complementEdit.id, external_code: externalCodeComplement, maximum, minimum, increments }];
      });
    }
    complementSave = [{ name: complementName, id: complementEdit.id, external_code: externalCodeComplement, maximum, minimum, increments }];
  } else {
    const complementsId = uuidV4();
    const increments: any = [...increment];
    if (increments.length > 0) {
      increments.forEach((item: any) => {
        // @ts-ignore
        item.id = item.id ? item.id : uuidV4();
        // @ts-ignore
        item.product_id = complementsId;
        item.parentName = complementName;
        complementSave = [
          { name: complementName, id: complementsId, external_code: externalCodeComplement, maximum, minimum, isNew: 'newComplement', increments },
        ];
      });
    }
    complementSave = [
      { name: complementName, id: complementsId, external_code: externalCodeComplement, maximum, minimum, isNew: 'newComplement', increments },
    ];
  }
  dispatch({ type: 'SAVE_COMPLEMENT', complementSave });
  dispatch({ type: 'QUANTITY_MINIMUM', complementName: '' });
  dispatch({ type: 'QUANTITY_MAXIMUM', complementName: '' });

  dispatch({ type: 'COMPLEMENT_NAME', complementName: '' });
  onClose();
  };

  const handleCancelIncrement = () => {
    if (copyComplementAndIncrement.id) {
      const index = complementData.findIndex((complementItem: any) => complementItem.id === copyComplementAndIncrement.id);
      complementData.splice(index, 1);
      const complement = complementData;
      dispatch({ type: 'REMOVE_COMPLEMENT', complement });
      const complementSave = [copyComplementAndIncrement];
      dispatch({ type: 'SAVE_COMPLEMENT', complementSave });
    }
    dispatch({ type: 'COMPLEMENT_NAME', complementName: '' });
    dispatch({ type: 'QUANTITY_MINIMUM', complementName: '' });
    dispatch({ type: 'QUANTITY_MAXIMUM', complementName: '' });
    dispatch({ type: 'CLEAR_INCREMENT', increment });
    onClose();
  };

  const [complementTab, setComplementTab] = useState<boolean>(true);
  const [buttonContinue, setButtonContinue] = useState<boolean>(true);

  const handleContinueTabs = () => {
    const index: number = tabIndex + 1;
    switch (index) {
      case 0:
        setComplementTab(true);
        setButtonContinue(true);
        setTabIndex(index);
        break;
      case 1:
        setComplementTab(false);
        setButtonContinue(true);
        setTabIndex(index);
        break;
      default:
    }
  };

  const handleSelectTabs = (index: number) => {
    switch (index) {
      case 0:
        setComplementTab(true);
        setTabIndex(index);
        break;
      case 1:
        setComplementTab(true);
        setTabIndex(index);
        break;
      default:
    }
  };

  useEffect(() => {
    if (tabIndex !== 0) {
      return;
    }
    if (complementName) {
      setButtonContinue(false);
    } else {
      setButtonContinue(true);
    }
  }, [complementName, tabIndex]);

  useEffect(() => {
    if (tabIndex !== 1) {
      return;
    }
  let isValid: boolean = true;
  if (incrementsData.length !== 0) {
    incrementsData.forEach((line: any) => {
      if (!line.name || !line.price) {
        isValid = false;
      }
  });
  } else {
    isValid = false;
  }
  if (isValid) {
    setButtonContinue(false);
  } else {
    setButtonContinue(true);
  }
  }, [incrementsData, tabIndex]);

  const handleChangeStatusProduct = async (type: string) => {
    if (type === 'ativo') {
      // const externalCode = Math.floor(Math.random() * 65536);
      setIsProduct(true);
    } else {
      setIsProduct(false);
      dispatch({ type: 'EXTERNAL_CODE_COMPLEMENT', externalCodeComplement: null });
    }
  };

  const handleWithProductOrComplement = () => (
    <SwitchContainer>
    <Grid component="label" container alignItems="center" spacing={0}>
      <Grid item />
      <span>Produto</span>
      <Grid item>
        {isProduct === true ? (
          <Switch
            checked
            onChange={() => handleChangeStatusProduct('dasativado')}
            name="product"
            />
            )
            :
            (
              <Switch
                checked={false}
                onChange={() => handleChangeStatusProduct('ativo')}
                name="product"
          />
        )}
      </Grid>
      <Grid item />
    </Grid>
    </SwitchContainer>
  );

  return (
    <Container>
      <SubContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <TabsContainer>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => handleSelectTabs(index)}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <TabList>
                <Tab>Detalhes</Tab>
                <Tab disabled={complementTab}>Complementos</Tab>
              </TabList>
            </div>
            <TabPanel>
              <Card>
                <InputContainer>
                  <InputContainer>
                    <Text>Nome:</Text>
                    <Input
                      type="text"
                      placeholder="Nome do complemento"
                      value={complementName}
                      onChange={(value: any) =>
                        dispatch({ type: 'COMPLEMENT_NAME', complementName: value.target.value })
                      }
                    />
                  </InputContainer>
                  {handleWithProductOrComplement()}
                </InputContainer>
                <InputContainer>
                    <InputContainer>
                      <Text>Mínima:</Text>
                      <Input
                        type="number"
                        placeholder="Quantidade mínima"
                        value={minimum}
                        onChange={(value: any) =>
                          dispatch({ type: 'QUANTITY_MINIMUM', minimum: value.target.value })
                        }
                      />
                    </InputContainer>
                    <InputContainer>
                      <Text>Máxima:</Text>
                      <Input
                        type="number"
                        placeholder="Quantidade máxima"
                        value={maximum}
                        onChange={(value: any) =>
                          dispatch({ type: 'QUANTITY_MAXIMUM', maximum: value.target.value })
                        }
                        />
                    </InputContainer>
                    {isProduct === true ? (
                    <InputContainer>
                    <Text>Código:</Text>
                      <Input
                        type="number"
                        placeholder="Código do produto"
                        value={externalCodeComplement}
                        onChange={(value: any) =>
                          dispatch({ type: 'EXTERNAL_CODE_COMPLEMENT', externalCodeComplement: value.target.value })
                        }
                      />
                    </InputContainer>
                    ) : null}
                </InputContainer>
              </Card>
            </TabPanel>
            <TabPanel>
              <ContainerComplement>
                <SubContainerComplement>
                  {incrementsData
                    ? incrementsData.map((item: any, index: number) => (
                        <CardComplement
                          name={item.name}
                          maximum={item.maximum}
                          minimum={item.minimum}
                          price={item.price}
                          ordernation={item.ordernation}
                          index={index}
                          isNew={item.isNew}
                          status={item.status}
                          externalCode={item.external_code}
                          handleDeleteIncrement={() => handleDeleteIncrement(index)}
                        />
                      ))
                    : null}
                  <ContainerButtonIncrement>
                    <Button onClick={handleAddingComplement} title="+ Adicionar complemento" />
                  </ContainerButtonIncrement>
                </SubContainerComplement>
              </ContainerComplement>
            </TabPanel>
          </Tabs>
        </TabsContainer>
        <ButtonsContainer>
          <ButtonsContainerCancel>
            <Button onClick={() => handleCancelIncrement()} title="Cancelar" />
          </ButtonsContainerCancel>
          <ButtonsContainerContinue>
            {tabIndex === 0 ? (
              <Button disabled={buttonContinue} onClick={handleContinueTabs} title="Continuar" />
            ) : (
              <Button disabled={buttonContinue} onClick={() => handleSaveIncrement()} title="Salvar" />
            )}
          </ButtonsContainerContinue>
        </ButtonsContainer>

      </SubContainer>
      {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            Produto Adicionado com Sucesso !
          </Alert>
        </Snackbar>
      ) : null}
    </Container>
  );
};

export default CatalogAddComplement;
