import React from 'react';
import { Container, Main } from './styles';
import Dashboard from '../../../components/Dashboard';
import NavBar from '../../../components/NavBar';

const Home = () => (
  <Container>
    <Main>
      <Dashboard />
      <NavBar />
    </Main>
  </Container>
);

export default Home;
