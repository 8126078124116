import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 2px 2px 5px #c4c4c4;
`;
