/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Typography, FormControl, FormControlLabel, RadioGroup, Radio, Snackbar } from '@material-ui/core';
import { v4 as uuidV4 } from 'uuid';
import Button from '../Button';
import Input from '../InputBase';
import api from '../../service/api';
import { ICategoriesDTO } from '../../interfaces/IProductDTO';
import Dropzone from '../Dropzone';
import AddComplement from '../CatalogAddComplement';
import TableComplement from '../TableComplement';
import mMoeda from '../../util/float2moeda';
import {
  Container,
  SubContainer,
  TitleContainer,
  Title,
  ButtonsContainer,
  ButtonsContainerCancel,
  ButtonsContainerContinue,
  ContainerTabPanelDetails,
  CardsContainer,
  Card,
  InputContainer,
  SelectContainer,
  Text,
  Select,
  DropzoneContainer,
  ImageContainer,
  ContainerComplement,
  ContainerAvailability,
  ContainerFormControl,
  ContainerButtonComplement,
  TabsContainer,
} from './styles';

interface IRouteParams {
  onClose: any;
  handleCategoryProduct: any;
}

const CatalogAddProduct = ({ onClose, handleCategoryProduct }: IRouteParams) => {
  const dispatch: any = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [title, setTitle] = useState<string>('Novo item');
  const [status, setStatus] = useState<string>('A');
  const [complement, setComplement] = useState<boolean>(false);
  const [increment, setIncrement] = useState<boolean>(false);
  const [groupCategory, setGroupCategory] = useState<ICategoriesDTO[]>([]);
  const [complementEdit, setComplementEdit] = useState<any[]>([]);

  const category: string = useSelector((state: any) => state.category);
  const name: string = useSelector((state: any) => state.name);
  const description: string = useSelector((state: any) => state.description);
  const group: string = useSelector((state: any) => state.group);
  const price: string = useSelector((state: any) => state.price);
  const externalCode: string = useSelector((state: any) => state.externalCode);

  const complements: any = useSelector((state: any) => state.complementData);

  const [selectedFile, setSelectedFile] = useState<File>();

  const [snakOpen, setSnackOpen] = useState<boolean>();
  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

  const [token] = useState(localStorage.getItem('token'));
  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const thumbs = selectedFile ? (
    <>
      <div key={selectedFile.name}>
        <img src={URL.createObjectURL(selectedFile)} />
      </div>
    </>
  ) : null;

  useEffect(() => {
    api.get('products/group', config).then((response) => {
      setGroupCategory(response.data);
    });
  }, []);

  useEffect(() => {
    if (!name) {
      setTitle('Novo item');
      return;
    }
    setTitle(name);
  }, [name]);

  async function handleSubmit() {
    const productId = uuidV4();
    const complementData: any = [];
    // @ts-ignore
    const store = JSON.parse(localStorage.getItem('authStore'));
    const productDataForm = new FormData();
    productDataForm.append('id', productId);
    productDataForm.append('typecast', `${category}` || 'P');
    productDataForm.append('name', `${name || ''}`);
    productDataForm.append('productId', `${group}`);
    productDataForm.append('description', `${description || ''}`);
    productDataForm.append('price', `${price}`);
    productDataForm.append('maximum', '0');
    productDataForm.append('minimum', '1');
    productDataForm.append('calculation', 'N');
    productDataForm.append('status', status);
    productDataForm.append('storeId', `${store ? store.id : ''}`);
    productDataForm.append('primaryLevel', 'N');
    productDataForm.append('complementPagination', '1');
    productDataForm.append('ordernation', '0');
    productDataForm.append('externalCode', externalCode);
    selectedFile ? productDataForm.append('image', selectedFile) : null;
    productDataForm.append('prodMode', 'N');

    if (complements.length) {
      complements.forEach((product: any, index: number) => {
        complementData.push(product);
        complementData[index].product_id = productId;
      });
    }

    await api
      .post('products/', productDataForm, config)
      .then(async (res) => {
        if (res.data.success === true && complements.length > 0) {
          await api.post('products/complement/', complementData, config);
        }
        handleCategoryProduct();
      })
      .finally(() => {
        setSnackOpen(!snakOpen);
        setTimeout(() => {
          handleCancelProduct();
        }, 3000);
      });
  }
  function handleIncremente() {
    setIncrement(false);
    setComplement(false);
  }

  const handleEditComplement = (editComplements: any) => {
    setComplementEdit(editComplements);
    setIncrement(true);
  };

  const handleCancelProduct = () => {
    dispatch({ type: 'CLEAR_INITIAL_STATE', complementName: '' });
    dispatch({ type: 'CLEAR_INCREMENT', increment });
    dispatch({ type: 'CLEAR_COMPLEMENT', complement });
    onClose();
  };

  const [priceTab, setPriceTab] = useState<boolean>(true);
  const [complementTab, setComplementTab] = useState<boolean>(true);
  const [availabilityTab, setAvailabilityTab] = useState<boolean>(true);
  const [buttonContinue, setButtonContinue] = useState<boolean>(true);

  const handleContinueTabs = () => {
    const index: number = tabIndex + 1;
    switch (index) {
      case 1:
        setPriceTab(false);
        setComplementTab(true);
        setAvailabilityTab(true);
        setTabIndex(index);
        setButtonContinue(true);
        break;
      case 2:
        setPriceTab(false);
        setComplementTab(false);
        setAvailabilityTab(true);
        setTabIndex(index);
        setButtonContinue(false);
        break;
      case 3:
        setPriceTab(false);
        setComplementTab(false);
        setAvailabilityTab(false);
        setTabIndex(index);
        setButtonContinue(false);
        break;
      default:
    }
  };

  const handleSelectTabs = (index: number) => {
    switch (index) {
      case 0:
        setPriceTab(true);
        setComplementTab(true);
        setAvailabilityTab(true);
        setTabIndex(index);
        setButtonContinue(false);
        break;
      case 1:
        setPriceTab(false);
        setComplementTab(true);
        setAvailabilityTab(true);
        setTabIndex(index);
        setButtonContinue(false);
        break;
      case 2:
        setPriceTab(false);
        setComplementTab(false);
        setAvailabilityTab(true);
        setTabIndex(index);
        setButtonContinue(false);
        break;
      case 3:
        setPriceTab(false);
        setComplementTab(false);
        setAvailabilityTab(false);
        setTabIndex(index);
        setButtonContinue(false);
        break;
      default:
    }
  };

  useEffect(() => {
    if (tabIndex !== 0) {
      return;
    }
    if (name && group && externalCode) {
      setButtonContinue(false);
    } else {
      setButtonContinue(true);
    }
  }, [name, group, externalCode, tabIndex]);

  useEffect(() => {
    if (tabIndex !== 1) {
      return;
    }
    const isPrice = price.replace('.', '');
    const isPriceValid = isPrice.replace(',', '.');
    // @ts-ignore
    if (isPriceValid && Math.sign(isPriceValid) > 0) {
      setButtonContinue(false);
    } else {
      setButtonContinue(true);
    }
  }, [price, tabIndex]);

  return (
    <Container>
      {increment ? (
        <AddComplement onClose={handleIncremente} complementId={complementEdit} />
      ) : (
        <SubContainer>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <TabsContainer>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => handleSelectTabs(index)}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TabList>
                  <Tab>Detalhes</Tab>
                  <Tab disabled={priceTab}>Preço</Tab>
                  <Tab disabled={complementTab}>Itens</Tab>
                  <Tab disabled={availabilityTab}>Disponibilidade</Tab>
                </TabList>
              </div>
              <TabPanel>
                <ContainerTabPanelDetails>
                  <CardsContainer>
                    <DropzoneContainer>
                      <Dropzone onFileUploaded={setSelectedFile} />
                      <ImageContainer>{thumbs}</ImageContainer>
                    </DropzoneContainer>
                    <Card>
                      <InputContainer>
                        <InputContainer>
                          <Text>Nome:</Text>
                          <Input
                            type="text"
                            placeholder=""
                            value={name}
                            onChange={(value: any) => dispatch({ type: 'PRODUCT_NAME', name: value.target.value })}
                          />
                        </InputContainer>
                        <InputContainer>
                          <Text>Código:</Text>
                          <Input
                            type="Number"
                            placeholder=""
                            value={externalCode}
                            // eslint-disable-next-line prettier/prettier
                            onChange={(value: any) => dispatch({ type: 'EXTERNAL_CODE', externalCode: value.target.value })}
                          />
                        </InputContainer>
                      </InputContainer>
                      <InputContainer>
                        <SelectContainer>
                          <Text>Grupo:</Text>
                          <Select
                            value={group}
                            onChange={(value) => dispatch({ type: 'GROUP_SELECTED', group: value.target.value })}>
                            <option value="">{}</option>
                            {groupCategory?.map((groupP) => (
                              <option value={groupP.id}>{groupP.name}</option>
                            ))}
                          </Select>
                        </SelectContainer>
                      </InputContainer>
                      <InputContainer>
                        <Text>Descrição:</Text>
                        <textarea
                          value={description}
                          onChange={(value) => dispatch({ type: 'DESCRIPTION', description: value.target.value })}
                        />
                      </InputContainer>
                    </Card>
                  </CardsContainer>
                </ContainerTabPanelDetails>
              </TabPanel>
              <TabPanel>
                <InputContainer>
                  <InputContainer>
                    <Text>Preço:</Text>
                    <Input
                      type="text"
                      placeholder=""
                      maxLength={10}
                      value={price}
                      onChange={(value: any) => dispatch({ type: 'PRICE', price: mMoeda(value.target.value) })}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Text>Moeda:</Text>
                    <Input disabled type="text" placeholder="R$" />
                  </InputContainer>
                </InputContainer>
              </TabPanel>
              <TabPanel>
                <ContainerComplement>
                  <ContainerFormControl>
                    <FormControl component="fieldset">
                      <Typography>Selecione uma opção</Typography>
                      <RadioGroup aria-label="gender" defaultValue="noComplement" name="radio-buttons-complement">
                        <FormControlLabel
                          onChange={() => setComplement(false)}
                          value="noComplement"
                          control={<Radio />}
                          label="Não, este item não tem complementos"
                        />
                        <FormControlLabel
                          onChange={() => setComplement(true)}
                          value="withComplement"
                          control={<Radio />}
                          label="Sim, este item tem tem complementos"
                        />
                      </RadioGroup>
                    </FormControl>
                  </ContainerFormControl>
                  {complement ? (
                    <ContainerButtonComplement>
                      <Button onClick={() => handleEditComplement('new')} title="+ Criar grupo de itens" />
                    </ContainerButtonComplement>
                  ) : null}
                </ContainerComplement>
                <div style={{ marginTop: '5%' }}>
                  <TableComplement
                    handleEditComplement={(editComplement: any) => handleEditComplement(editComplement)}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <ContainerAvailability>
                  <FormControl component="fieldset">
                    <Typography>Status de venda</Typography>
                    <RadioGroup aria-label="gender" defaultValue="active" name="radio-buttons-availability">
                      <FormControlLabel
                        onChange={() => setStatus('A')}
                        value="active"
                        control={<Radio />}
                        label="Ativo"
                      />
                      <FormControlLabel
                        onChange={() => setStatus('D')}
                        value="disabled"
                        control={<Radio />}
                        label="Desativado"
                      />
                    </RadioGroup>
                  </FormControl>
                </ContainerAvailability>
              </TabPanel>
            </Tabs>
          </TabsContainer>
          <ButtonsContainer>
            <ButtonsContainerCancel>
              <Button onClick={handleCancelProduct} title="Cancelar" />
            </ButtonsContainerCancel>
            <ButtonsContainerContinue>
              {tabIndex === 3 ? (
                <Button onClick={handleSubmit} title="Salvar" />
              ) : (
                <Button disabled={buttonContinue} onClick={handleContinueTabs} title="Continuar" />
              )}
            </ButtonsContainerContinue>
          </ButtonsContainer>
        </SubContainer>
      )}
      {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={5000} onClose={() => onClose()}>
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            Produto Adicionado com Sucesso !
          </Alert>
        </Snackbar>
      ) : null}
    </Container>
  );
};

export default CatalogAddProduct;
