import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(76, 216, 64);
  background: linear-gradient(0deg, rgb(76, 216, 64) 0%, rgb(49, 177, 102) 50%, rgba(82, 82, 202, 0.6) 100%);
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

export const TypingDemo = styled.div`
  position: fixed;
  width: 22ch;
  animation: typing 2s steps(30), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  background-position: top top;
  background-size: cover;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3em;

  @keyframes typing {
    from {
      width: 0;
      color: #00ff7f;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
`;

export const ButtonHome = styled.div`
  position: absolute;
  height: auto;
  width: 100%;
  margin-top: 28%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    color: #fff;
    padding: 6px 22px;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
    background-color: rgba(255, 255, 255, 0.1);
    border: solid 2px transparent;
    cursor: pointer;
  }
`;
