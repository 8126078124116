import styled from 'styled-components';
import { colors } from '../../style/styles';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  border-bottom: transparent;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px;
  background-color: none;
  border: none;

  &:hover {
    svg {
      color: ${colors.hoverBlue} !important;
    }
  }

  svg {
    margin: 0px 5px;
    color: ${colors.blue} !important;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;
