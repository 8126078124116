import styled from 'styled-components';

export const Container = styled.input`
  padding: 12px;
  border: none;
  width: 250px;
  background-color: #fff;
  border: 1px solid #dcdcde;
  border-radius: 5px;
  margin: 0px 10px;
  border-style: none;
`;
