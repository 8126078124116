import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(99.2% - 30px);
  height: 75px;
  align-items: center;

  box-shadow: 2px solid #181818;
  padding: 0px 20px;
  flex-direction: row;

  margin: 0px;

  svg {
    height: 30px;
    width: 30px;
    border-style: none;
    border-radius: 20px;
    margin: 0px 10px;
    color: #fff;
    background-color: #000;
  }

  input {
    padding: 12px;
    border: none;
    width: 250px;
    background-color: #fff;
    border-radius: 25px;
    margin: 0px 10px;
    border-style: none;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
