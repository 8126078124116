/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import { MailOutlineOutlined, Menu, NotificationsOutlined, AccountCircleOutlined } from '@material-ui/icons';

import Drawer from '../Drawer';
import { Container, SubContainer } from './styles';
import InputDashboard from '../InputBase';
import IconButton from '../IconButton';
import ModalNavbar from '../ModalNavbar';
import ModalProfile from '../ModalProfile';
import { IOrderDTO } from '../../interfaces/IOrderDTO';
import api from '../../service/api';

const NavBar = () => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>();

  const [orders, setOrders] = useState<IOrderDTO>();

  // const [token] = useState(localStorage.getItem('token'));
  // const config = {
  //   headers: {
  //     authorization: 'Bearer ' + `${token}`,
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  // };

  return (
    <Container>
      <Drawer />
    </Container>
  );
};

export default NavBar;
