/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Typography, Snackbar } from '@material-ui/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import api from '../../service/api';
import Dropzone from '../Dropzone';
import Input from '../InputBase';
import {
  ContainerCategory,
  SubContainer,
  TitleContainer,
  Title,
  ContainerTabPanelDetails,
  CardsContainer,
  DropzoneContainer,
  DropzoneSubContainer,
  ImageContainer,
  Card,
  InputContainer,
  ButtonsContainer,
  ButtonsContainerCancel,
  ButtonsContainerContinue,
  TabsContainer,
} from './styles';
import Button from '../Button';

interface IRouteParams {
  onClose: any;
  id: string;
}

const CatalogEditGroup = ({ id, onClose }: IRouteParams) => {
  const [token] = useState(localStorage.getItem('token'));
  const [name, setName] = useState<string>('');
  const [title, setTitle] = useState<string>('Nova categoria');

  const [fileResponse, setFileResponse] = useState<any>();
  const [selectedFileProduct, setSelectedFileProduct] = useState<File>();
  const [thumbsProduct, setThumbsProduct] = useState<any>();
  const [thumbProduct, setThumbProduct] = useState<boolean>();

  const [fileResponseFeatured, setFileResponseFeatured] = useState<any>();
  const [selectedFileFeatured, setSelectedFileFeatured] = useState<File>();
  const [thumbsFeatured, setThumbsFeatured] = useState<any>();
  const [thumbFeatured, setThumbFeatured] = useState<boolean>();

  const [buttonSaveDisabled, setButtonSaveDisabled] = useState<boolean>(true);

  const [snakOpen, setSnackOpen] = useState<boolean>();
  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

  const config = {
    headers: {
      authorization: 'Bearer ' + `${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    api.get(`products/id/${id}`, config).then((response) => {
      setFileResponse(response.data.image);
      setName(response.data.name);
      setFileResponseFeatured(response.data.imgProductFeatured);
    });
  }, []);

  useEffect(() => {
    if (selectedFileProduct) {
      setThumbsProduct(
        <>
          <div key={selectedFileProduct.name}>
            <img src={URL.createObjectURL(selectedFileProduct)} />
          </div>
        </>,
      );
      setThumbProduct(false);
    } else {
      setThumbProduct(true);
    }
  }, [selectedFileProduct]);

  useEffect(() => {
    if (selectedFileFeatured) {
      setThumbsFeatured(
        <>
          <div key={selectedFileFeatured.name}>
            <img src={URL.createObjectURL(selectedFileFeatured)} />
          </div>
        </>,
      );
      setThumbFeatured(false);
    } else {
      setThumbFeatured(true);
    }
  }, [selectedFileFeatured]);

  useEffect(() => {
    if (name === '') {
      setTitle('Nova categoria');
      setButtonSaveDisabled(true);
      return;
    }
    setButtonSaveDisabled(false);
    setTitle(name);
  }, [name]);

  function handleInput(set: any, value: any) {
    set(value.target.value);
  }

  async function handleSubmit() {
    // @ts-ignore
    const store = JSON.parse(localStorage.getItem('authStore'));
    const categoryDataForm = new FormData();
    categoryDataForm.append('id', id);
    categoryDataForm.append('typecast', 'G');
    categoryDataForm.append('name', `${name}`);
    categoryDataForm.append('productId', 'null');
    categoryDataForm.append('description', 'null');
    categoryDataForm.append('price', 'null');
    categoryDataForm.append('maximum', '0');
    categoryDataForm.append('minimum', '0');
    categoryDataForm.append('calculation', 'N');
    categoryDataForm.append('status', 'A');
    categoryDataForm.append('storeId', `${store ? store.id : ''}`);
    categoryDataForm.append('primaryLevel', 'Y');
    categoryDataForm.append('complementPagination', 'S');
    selectedFileProduct ? categoryDataForm.append('image', selectedFileProduct) : categoryDataForm.append('image', fileResponse);
    categoryDataForm.append('prodMode', 'N');

    await api
      .put('products/category/', categoryDataForm, config)
      .then(async (res) => {
        if (res.data.success === true && selectedFileFeatured) {
          const categoryData = new FormData();
          categoryData.append('id', res.data.id);
          selectedFileFeatured ? categoryData.append('image', selectedFileFeatured) : categoryData.append('image', fileResponseFeatured);
          await api.put('products/category/img-featured/', categoryData, config);
        }
      })
      .finally(() => {
        setSnackOpen(!snakOpen);
        setTimeout(() => {
          onClose();
        }, 3000);
      });
  }

  return (
      <ContainerCategory>
        <SubContainer>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <TabsContainer>
            <Tabs>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TabList>
                  <Tab>Detalhes da categoria</Tab>
                </TabList>
              </div>
              <TabPanel>
                <ContainerTabPanelDetails>
                  <CardsContainer>
                    <Card>
                      <Typography variant="subtitle2">Nome do produto:</Typography>
                      <InputContainer>
                        <InputContainer>
                          <Input
                            type="text"
                            placeholder=""
                            value={name}
                            onChange={(value: string) => handleInput(setName, value)}
                          />
                        </InputContainer>
                      </InputContainer>
                      <DropzoneContainer>
                        <Typography variant="subtitle2">Imagem do produto:</Typography>
                        <DropzoneSubContainer>
                          <Dropzone onFileUploaded={setSelectedFileProduct} />
                          {thumbProduct ? (
                            <ImageContainer><img src={fileResponse} /></ImageContainer>
                          ) : (<ImageContainer>{thumbsProduct}</ImageContainer>)}
                        </DropzoneSubContainer>
                      </DropzoneContainer>
                      <DropzoneContainer>
                        <Typography variant="subtitle2">Imagem de destaque:</Typography>
                        <DropzoneSubContainer>
                          <Dropzone onFileUploaded={setSelectedFileFeatured} />
                          {thumbFeatured ? (
                           <ImageContainer>
                             <img src={fileResponseFeatured} />
                           </ImageContainer>
                          )
                        :
                        (<ImageContainer>{thumbsFeatured}</ImageContainer>)}
                        </DropzoneSubContainer>
                      </DropzoneContainer>
                    </Card>
                  </CardsContainer>
                </ContainerTabPanelDetails>
              </TabPanel>
            </Tabs>
          </TabsContainer>
          <ButtonsContainer>
            <ButtonsContainerCancel>
              <Button onClick={() => onClose()} title="Cancelar" />
            </ButtonsContainerCancel>
            <ButtonsContainerContinue>
            <Button disabled={buttonSaveDisabled} onClick={handleSubmit} title="Salvar" />
            </ButtonsContainerContinue>
          </ButtonsContainer>
        </SubContainer>
        {snakOpen ? (
        <Snackbar open={snakOpen} autoHideDuration={5000} onClose={() => onClose()}>
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            Categoria editada com Sucesso !
          </Alert>
        </Snackbar>
      ) : null}
      </ContainerCategory>
  );
};
export default CatalogEditGroup;
