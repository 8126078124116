/* eslint-disable max-len */
import React from 'react';
import { Container } from './styles';

interface IPropsDTO {
  onChange?: any;
  placeholder?: string;
  type: string;
  disabled?: boolean;
  value?: any;
  readOnly?: boolean;
  required?: any;
  maxLength?: any;
}

const InputBase = ({
  placeholder,
  type,
  onChange,
  disabled = false,
  value,
  readOnly = false,
  required = false,
  maxLength,
}: IPropsDTO) => (
  <Container
    value={value}
    disabled={disabled}
    required={required}
    multiple
    onChange={onChange}
    type={type}
    placeholder={placeholder}
    readOnly={readOnly}
    maxLength={maxLength}
  />
);

export default InputBase;
